import type { FieldState } from '@pretto/zen/reveal/types/Field'

import { isDateValid, isLessThan } from '@pretto/app/src/Sentences/v2/lib/date'

export const handleCreditDateState = (
  date?: Date | null
): { state: FieldState; message?: 'invalidDate' | 'paidCredits' } => {
  if (!date) {
    return {
      state: 'default',
    }
  }

  if (!isDateValid(date)) {
    return {
      state: 'error',
      message: 'invalidDate',
    }
  }

  if (isLessThan(date, new Date())) {
    return {
      state: 'warning',
      message: 'paidCredits',
    }
  }

  return {
    state: 'default',
  }
}
