import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import Image from '@pretto/bricks/website/shared/components/Image'

import { PrettoLogotype as PrettoLogotypeComponent } from '@pretto/zen/reveal/logos/PrettoLogotype/PrettoLogotype'

import { CheckBold } from '@pretto/picto'

import styled from 'styled-components'

export const PartnerContainer = styled.div`
  padding: ${g(2.5)} ${g(3)};
  border: 1px solid #5076f6;
  border-radius: ${g(1)};
  background-color: #f2f2ff;
  text-align: center;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${g(3)};
`

export const PartnerLogo = styled(Image).attrs(({ path }) => ({
  path,
  options: {
    width: ng(10),
    height: ng(3),
  },
}))`
  margin-right: ${g(1)};
`

export const PrettoLogotype = styled(PrettoLogotypeComponent)`
  width: ${g(10)};
  height: ${g(3)};
  margin-left: ${g(1)};
`

export const PartnerDescription = styled.p`
  margin: ${g(2)} 0;
  text-align: center;
`

export const Cta = styled(ButtonInline).attrs({ isSecondary: true })`
  margin-bottom: ${g(2)};
`

export const PartnerRTBContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.mobileL}) {
    flex-direction: row;
  }
`

export const PartnerRTB = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: ${g(0.5)};
  }

  @media only screen and (min-width: ${breakpoints.mobileL}) {
    & + & {
      margin-left: ${g(2)};
      margin-top: 0;
    }
  }
`

export const Picto = styled(CheckBold)`
  width: ${g(2)};
  height: ${g(2)};
  background-color: #5076f6;
  border-radius: 100%;
  padding: 2px;
  color: white;
  margin-right: ${g(0.5)};
  flex-shrink: 0;
`
