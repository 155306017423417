import { Button } from '@pretto/zen/atoms/buttons/Button/Button'

import { useTracking } from '@pretto/app/src/lib/tracking'

import React, { useEffect } from 'react'

import * as S from './AdvisorModal.styles'

interface AdvisorModalProps {
  advisor: {
    name: string
    picturePath: string
  }
  href: string
  onClose: () => void
  isOpen: boolean
}

export const AdvisorModal: React.FC<AdvisorModalProps> = ({ advisor, href, onClose, isOpen }) => {
  const trackAction = useTracking()

  useEffect(() => {
    if (isOpen) {
      trackAction('ADVISOR_MODAL_OPENED')
    }
  }, [isOpen])

  const handleBookAppointment = () => {
    trackAction('ADVISOR_MODAL_BOOK_APPOINTMENT')
    onClose()
  }

  return (
    <S.Dialog isOpen={isOpen} onRequestClose={onClose}>
      <S.ClosePicto onClick={onClose} />
      <S.Title>Bonne nouvelle !</S.Title>
      <S.Description>
        <p>Votre projet immobilier vient de faire un pas de géant ! Félicitations 🔥</p>
        <p>
          Les conditions d’emprunt évoluant rapidement, nous devons être réactifs pour trouver le financement de votre
          projet.
        </p>
        <p>
          Maintenant que le compte à rebours est lancé, c’est <strong>{advisor.name}</strong>, Expert crédit qui devient
          votre interlocuteur principal. Vous trouverez ses coordonnées directement dans votre Espace Pretto.
        </p>
        <p>
          Choisissez dès maintenant un créneau dans <S.AgendaLink href={href}>son agenda</S.AgendaLink> pour connaître
          les banques disponibles pour votre projet.
        </p>
      </S.Description>
      <S.Advisor>
        {advisor ? (
          <>
            <S.Picture src={advisor.picturePath} />
            <div>
              <S.AdvisorTitle>Votre Expert</S.AdvisorTitle>
              <span>{advisor.name}</span>
            </div>
          </>
        ) : (
          <>
            <S.Loader />
            <S.LoaderText>Attribution en cours…</S.LoaderText>
          </>
        )}
      </S.Advisor>
      <S.CtaContainer>
        <Button onClick={handleBookAppointment} href={href}>
          Prendre RDV
        </Button>
        <Button isSecondary onClick={onClose}>
          Pas maintenant
        </Button>
      </S.CtaContainer>
    </S.Dialog>
  )
}
