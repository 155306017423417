import { formatDecodePhone } from '@pretto/app-core/lib/filters'

import { EBOOK_URL } from '@pretto/config-front/constants'
import {
  Archive,
  Bell,
  BubbleDouble,
  Calendar,
  Envelope,
  ExclamationMarkCircle,
  Files,
  Folder,
  House,
  HouseMagnifier,
  Lock,
  MagnifyingGlassLeft,
  Phone,
  QuestionMarkCircle,
  Smartphone,
  Star,
  Tune,
  UserBook,
  UserCircle,
} from '@pretto/picto'

export const menuItems = {
  account: {
    href: '/account',
    label: 'Mon compte',
    picto: UserCircle,
  },
  appointment: ({ advisor, hasFeatureAccess }) =>
    advisor?.email &&
    !hasFeatureAccess(['NO_APPOINTMENT']) && {
      href: '/booking',
      label: 'Prendre rendez-vous',
      picto: Calendar,
    },
  callAnExpert: {
    href: '/application/documents?category=appointment',
    label: 'Besoin de parler à un Expert ?',
    picto: Phone,
  },
  dashboard: {
    href: '/',
    label: 'Tableau de bord',
    picto: House,
  },
  disconnect: {
    color: 'error1',
    href: '/logout',
    label: 'Me déconnecter',
    picto: Lock,
  },
  documents: {
    href: '/application/documents',
    label: 'Documents',
    picto: Files,
  },
  email: ({ advisor }) =>
    advisor?.email && {
      isUnavailable: !!advisor?.holidays_start_date,
      ...(advisor?.holidays_start_date ? {} : { href: `mailto:${advisor?.email}` }),
      label: `${advisor?.email}`,
      picto: Envelope,
    },
  faq: {
    href: 'https://faq.pretto.fr/fr/',
    label: 'FAQ',
    picto: QuestionMarkCircle,
  },
  folder: {
    href: '/application',
    label: 'Dossier',
    picto: Folder,
  },
  gtcs: {
    href: 'https://www.pretto.fr/cgu/',
    label: 'CGV et CGU',
    picto: UserBook,
  },
  guideBook: {
    href: EBOOK_URL,
    label: `Le Guide de l'acheteur`,
    picto: MagnifyingGlassLeft,
  },
  legaleNotices: {
    href: 'https://www.pretto.fr/mentions-legales/',
    label: 'Mentions légales',
    picto: ExclamationMarkCircle,
  },
  personnalData: {
    href: '/privacy',
    label: 'Données personnelles',
    picto: Archive,
  },

  phone: ({ advisor }) =>
    advisor?.phone && {
      isUnavailable: !!advisor?.holidays_start_date,
      ...(advisor?.holidays_start_date ? {} : { href: `sms://${advisor.phone}` }),
      label: formatDecodePhone(advisor.phone),
      picto: Smartphone,
    },
  propertySearch: ({ data, hasFeatureAccess }) =>
    !hasFeatureAccess(['BUYOUT']) && {
      href: data?.id ? `/property-search/alerts/${data.id}/edit` : `/property-search/alerts/create`,
      label: 'Ma recherche de bien',
      picto: HouseMagnifier,
    },
  simulation: {
    href: `/simulation`,
    label: 'Simulation',
    picto: Tune,
  },
  sponsoring: {
    href: '/sponsorship',
    label: 'Mon espace parrainage',
    picto: Star,
  },
  subscriptions: ({ getAccountId }) => ({
    href: `https://subscriptions.pretto.fr/manager/?accountId=${getAccountId()}`,
    label: 'Mes abonnements',
    picto: Bell,
  }),
  support: ({ onChat }) => ({
    label: 'Contacter le support',
    onClick: onChat,
    picto: BubbleDouble,
  }),
}
