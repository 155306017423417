/* eslint-disable max-lines */
import { gql } from '@apollo/client'

import { Financing, Location, Project } from './fragments'

// offer
export const AGREEMENT = gql`
  query Agreement {
    agreement {
      id
      bank
      comment
      contribution
      duration
      offers {
        bankCriterias {
          scoringIra
          iraDescription
          scoringInsurance
          insuranceDescription
          scoringModularity
          modularityDescription
          scoringReportDeadline
          reportDeadlineDescription
          scoringAverageResponseTime
          averageResponseTimeDescription
          scoringRse
          rseDescription
          scoringDigitization
          digitizationDescription
          scoringCustomerExperience
          customerExperienceDescription
          scoringBankingCost
          bankingCostDescription
        }
        amount
        applicationFees
        bankDescription
        bankLogo
        bankName
        bridgePayment
        bridgeRate
        bridgeValue
        concessions
        duration
        externalInsurance
        franchise
        guaranty
        guarantyKind
        insuranceCost
        insurancePayment
        insurancePolicies
        interests
        payment
        rate
        renegotiationSavings
        taeg
      }
      paymentMax
      paymentMin
      price
      requestedConcessions
      requestedExternalInsurance
      requestedInsurancePolicies
      requestedIra
      requestedModularity
      requestedReportDeadline
      requestedTransferability
    }
    project {
      id
      projectKind: project_kind
    }
  }
`

// confirm
export const CONFIRM = gql`
  query Confirm {
    analysis {
      applicationFees
      bridge {
        remaining
        value
      }
      contribution
      creditCosts
      guarantyFees
      loanAmount
      payment
      penaltyFees
      ptz {
        amount
        duration
        franchise
      }
    }
    brokerage_fees {
      id
      amount
    }
    deal {
      id
      mandate
      mandateStatus {
        signed
        signatures {
          id
          signedAt
        }
      }
    }
    project {
      id
      facts {
        agency_fees
        notary_fees
      }
      good {
        usage
      }
      project_kind
      purchase {
        furniture_price
        property_price
        works_price
        build_price
        land_price
      }
      renegotiation {
        remaining_principal
        works_amount
      }
      request {
        duration
      }
    }
    brokerage_fees {
      id
      amount
    }
  }
`
export const SEPA = gql`
  query SepaStatus {
    deal {
      id
      mandateStatus {
        signatures {
          id
          signedAt
        }
      }
      sepaStatus {
        signed
      }
    }
    next_booking {
      start
    }
  }
`

// overview
export const OVERVIEW = gql`
  query Overview {
    analysis {
      contribution
    }
    best_result {
      financing {
        bridge {
          remaining
          value
        }
        contribution
        creditCosts
        guarantyFees
        applicationFees
        loanAmount
        loanDuration
        payment
        penaltyFees
        ptz {
          amount
          duration
          franchise
        }
        savings
      }
    }
    brokerage_fees {
      id
      amount
    }
    project {
      id
      good {
        usage
      }
      facts {
        agency_fees
        notary_fees
      }
      project_kind
      purchase {
        build_price
        furniture_price
        land_price
        property_price
        works_price
      }
      renegotiation {
        remaining_principal
        works_amount
      }
    }
  }
`

// sentences
export const PROJECT = gql`
  query Project {
    project {
      eco_works {
        global_renovation
        sanitation
        insulation_roofs
        insulation_exterior_walls
        insulation_exterior_windows_and_doors
        low_floor_insulation
        hot_water_systems
        heating_renewable_energy_source
        hot_water_renewable_energy_source
      }
      id
      contribution
      facts {
        raw_incomes
      }
      project_kind
      mortgagors_arrangement
      solicited_banks {
        bank_slug
        duration
        id
        rate
      }
      ...Profile
      ...Good
      ...Purchase
      ...Renegotiation
    }
  }
  ${Project.fragments.profile}
  ${Project.fragments.good}
  ${Project.fragments.purchase}
  ${Project.fragments.renegotiation}
`

// onboarding
export const ONBOARDING = gql`
  query Onboarding {
    next_booking {
      start
    }
    project {
      id
      facts {
        non_resident
      }
      solicited_banks {
        bank_slug
        blocked
        id
      }
      solicited_brokers {
        duration
        id
        name
        rate
        status
      }
    }
  }
`
export const ADVISOR = gql`
  query Advisor {
    advisor {
      id
      bio
      email
      holidays_end_date
      holidays_start_date
      largePicturePath
      mediumPicturePath
      name
      pair_name
      pair_email
      phone
      visioUrl
      visio
      absences {
        end: endAt
        start: startAt
      }
      allowPeriods {
        end: endAt
        start: startAt
      }
      availabilities {
        allow_day_and_time: specificDayAndTime {
          day
          start
          end
        }
      }
      role
    }
    deal {
      id
      appointmentKind
    }
  }
`

export const BOOKING = gql`
  query Booking {
    next_booking {
      end
      start
      isVisio
    }
    deal {
      id
      appointmentKind
      isLowPotential
    }
  }
`

// simulation
export const ELIGIBILITY = gql`
  query Eligibility($override: String) {
    eligibility(project_override: $override)
  }
`

export const OPTIMIZE_SIMULATION = gql`
  query OptimizeSimulation($override: String) {
    optimize_contribution(project_override: $override) {
      ...OptimizedResult
    }
    optimize_payment(project_override: $override) {
      ...OptimizedResult
    }
    optimize_rate(project_override: $override) {
      ...OptimizedResult
    }
  }
  ${Financing.fragments.optimizedResult}
`

export const SIMULATION = gql`
  query Simulation($override: String) {
    simulation(project_override: $override) {
      ...SimulationResult
    }
  }
  ${Financing.fragments.simulationResult}
`

export const BIG_SIMULATION = gql`
  ${Financing.fragments.resultProject}
  ${Financing.fragments.simulationResult}

  query BigSimulation($override: String) {
    big_simulation(project_changes: $override) {
      ... on ReduceAmbitionSimulationType {
        data {
          project {
            contribution
            good {
              usage
            }
            project_kind
            profile {
              facts {
                total_savings
              }
            }
            purchase {
              property_price
              land_price
              build_price
              works_price
            }
          }

          reduce_ambition_contribution {
            project {
              contribution
            }
          }
          reduce_ambition_price {
            project {
              facts {
                price_before_fees
              }
              purchase {
                build_price
                land_price
                property_price
                works_price
              }
            }
          }
        }
      }
      ... on ArrangeCreditSimulationType {
        data {
          project {
            good {
              usage
            }
            project_kind
          }
          pay_off_credits_simulation {
            project {
              contribution
              profile {
                credits {
                  becoming
                  id
                  payment
                }
              }
            }
          }
          smooth_credits_simulation {
            project {
              profile {
                credits {
                  becoming
                  id
                  payment
                }
              }
            }
          }
        }
      }
      ... on SimulationErrorType {
        data {
          eligibility
          project {
            ...ResultProject
          }
          results {
            ...SimulationResult
          }
        }
      }
      ... on SimulationErrorSignupType {
        data {
          eligibility
          project {
            ...ResultProject
          }
          results {
            ...SimulationResult
          }
        }
      }
      ... on SimulationResultType {
        data {
          project {
            ...ResultProject
          }
          brokerage_fees {
            id
            amount
          }
          results {
            ...SimulationResult
          }
        }
      }
    }
  }
`

export const SIMULATION_PROJECT = gql`
  query SimulationProject {
    project {
      contribution
      facts {
        delegation_insurance_rate
        detailed_charges {
          child_support
          credits
          other_charges
          rent
        }
        insurance_rate
        max_indebtedness_rate {
          max
          min
        }
        min_remaining_life_amount
        notary_fees
        raw_incomes
        works_share
      }
      good {
        localisation {
          country
          zipcode
        }
        property_kind
        usage
      }
      profile {
        live_together
        children
        credits {
          kind
        }
        facts {
          max_age
          total_savings
        }
        mortgagors {
          address {
            ...Location
          }
          age
          contract
          facts {
            min_seniority
            non_fundable_reason
            seniority
          }
          housing
        }
      }
      project_kind
      purchase {
        build_price
        furniture_price
        land_price
        property_price
        works_price
      }
      request {
        duration
      }
      renegotiation {
        facts {
          remaining_duration
          remaining_interests
        }
        remaining_principal
        works_amount
      }
    }
    brokerage_fees {
      id
      amount
    }
  }
  ${Location}
`

// steps
export const STEPS = gql`
  query Steps {
    deal {
      id
      mandateStatus {
        signatures {
          id
          signedAt
        }
      }
    }
  }
`

export const PROPERTY = gql`
  query Property {
    project {
      id
      good {
        property_kind
      }
      purchase {
        agreement_sign_date
        build_price
        land_price
        property_price
        works_price
      }
    }
  }
`

export const MATURITY_CARD = gql`
  query MaturityCard {
    project {
      id
      purchase {
        maturity
      }
    }
  }
`

// sponsorship
export const SPONSORSHIP = gql`
  query sponsorship {
    new_sponsorship {
      id
      link
      totalGains
      totalReferees
      referees {
        addTime
        name
        status
      }
    }
  }
`

export const MUTUALIZED_AGENDA = gql`
  query mutualizedAgenda {
    mutualizedAgenda {
      start
      kind
      advisor {
        id
        firstName
        lastName
        pictureLink
        isVisio
        role
      }
    }
  }
`

export const SCORE_FLAGS = gql`
  query ScoreFlags($attributionCookieLastClick: String) {
    scoreFlags(attributionCookieLastClick: $attributionCookieLastClick) {
      appointmentAllowed
      pushy
      callBackNowAllowed
    }
  }
`

// MUTATIONS
export * from './mutations'
