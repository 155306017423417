// deprecated
// use instead: @pretto/app/src/lib/form/useEmailField
import BaseInput from '@pretto/bricks/components/form/BaseInput'
import { validateEmail } from '@pretto/bricks/core/utility/form/validateEmail'

import { t } from '@pretto/app/src/lib/i18n'

import { encodeEmail, run } from 'mailcheck'
import { useState } from 'react'
import { Trans } from 'react-i18next'

import useFieldController from './useFieldController'

interface UseEmailFieldProps {
  defaultIsButtonLoading?: boolean
  defaultValue?: string
  inputProps?: {
    [key: string]: unknown
  }
  validators?: unknown[]
  [key: string]: unknown
}

const DOMAINS = [
  'aol.com',
  'facebook.com',
  'free.fr',
  'gmail.com',
  'gmx.fr',
  'hotmail.com',
  'hotmail.fr',
  'icloud.com',
  'laposte.net',
  'live.com',
  'live.fr',
  'me.com',
  'msn.com',
  'orange.fr',
  'outlook.com',
  'outlook.fr',
  'sfr.fr',
  'wanadoo.fr',
  'yahoo.com',
  'yahoo.fr',
]

const useEmailField = ({
  defaultIsButtonLoading = false,
  defaultValue = '',
  inputProps,
  validators = [],
  ...rootProps
}: UseEmailFieldProps = {}) => {
  const [value, setValue] = useState(defaultValue)
  const [isButtonLoading, setIsButtonLoading] = useState(defaultIsButtonLoading)

  const allValidators = [
    ...validators,
    (value: string) => ({
      condition: value && !validateEmail(value),
      isDebounced: true,
      message: t('form.invalidEmail'),
      state: 'error',
    }),
    (value: string) => {
      const suggestion = run({
        domains: DOMAINS,
        email: encodeEmail(value),
      })

      const handleCorrect = () => {
        if (!suggestion) {
          return
        }

        setValue(suggestion.full)
      }

      return {
        condition: suggestion !== undefined,
        isDebounced: true,
        message: (
          <Trans
            i18nKey="form.rectifyEmail"
            values={{ suggestion: suggestion?.full }}
            components={{ CorrectButton: <button onClick={handleCorrect} type="button" /> }}
          />
        ),
        state: 'warning',
      }
    },
  ]

  const { error, stateProps, ...fieldControls } = useFieldController(value, { inputProps, validators: allValidators })

  const handleChange = (value: string) => {
    setValue(value)
  }

  const props = {
    ...rootProps,
    ...stateProps,
    inputProps: {
      ...inputProps,
      type: 'email',
    },
    isButtonDisabled: !value || error,
    isButtonLoading,
    onChange: handleChange,
    value,
  }

  const component = <BaseInput {...props} />

  return { ...fieldControls, component, error, setIsButtonLoading, setValue, value }
}

export default useEmailField
