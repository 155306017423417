import Dialog from '@pretto/bricks/components/overlays/Dialog'
import temporal from '@pretto/bricks/core/utility/temporal'

import {
  MainSectionNegotiations,
  MainSectionNegotiationsProps,
} from '@pretto/zen/dashboard/mainSections/MainSectionNegotiations/MainSectionNegotiations'
import {
  NegotiationStepBankProps,
  Status,
} from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepBank/NegotiationStepBank'
import { Status as StepSimpleStatus } from '@pretto/zen/dashboard/negotiationSteps/NegotiationStepSimple/NegotiationStepSimple'

import { getBankLogo } from '@pretto/app-core/lib/getBankLogo'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { NegotiationModalProps } from '@pretto/app/src/dashboard/components/NegotiationModal/NegotiationModal'
import { NegotiationPlaceholder } from '@pretto/app/src/dashboard/components/NegotiationPlaceholder/NegotiationPlaceholder'
import { Nps } from '@pretto/app/src/dashboard/components/Nps/Nps'
import {
  AdpModal,
  CheckBankModal,
  CheckGarantyModal,
  ComplianceCheckSelfModal,
  NegotiationModal,
  SentModal,
} from '@pretto/app/src/dashboard/components/negitiationModals'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import { getLastMandateDate } from '@pretto/app/src/dashboard/lib/getLastMandateDate'
import type { Config } from '@pretto/app/src/dashboard/types/card'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { NegotiationStateStatus, NegotiationStateType } from '@pretto/app/src/types/gateway/enums'
import BANKS from '@pretto/config/banks.json'

import { createElement, useEffect, useState } from 'react'

import { useNegotiationTransparencyCardQuery } from './negotiationTransparency.gateway.graphql'

const LABELS: Record<NegotiationStateType, string> = {
  [NegotiationStateType.ComplianceCheckSelf]: 'Vérification de la conformité avant envoi',
  [NegotiationStateType.Sent]: 'Envoi à la banque',
  [NegotiationStateType.CheckBank]: 'Étude par la banque',
  [NegotiationStateType.CheckGaranty]: 'Étude par l’organisme de garantie',
  [NegotiationStateType.Negotiation]: 'Finalisation des négociations',
  [NegotiationStateType.Adp]: 'Accord de principe',
}

const STEPS: NegotiationStateType[] = [
  NegotiationStateType.ComplianceCheckSelf,
  NegotiationStateType.Sent,
  NegotiationStateType.CheckBank,
  NegotiationStateType.CheckGaranty,
  NegotiationStateType.Negotiation,
  NegotiationStateType.Adp,
]

const CONTENT_DIALOG: Record<NegotiationStateType, React.FC<NegotiationModalProps>> = {
  [NegotiationStateType.ComplianceCheckSelf]: ComplianceCheckSelfModal,
  [NegotiationStateType.Sent]: SentModal,
  [NegotiationStateType.CheckBank]: CheckBankModal,
  [NegotiationStateType.CheckGaranty]: CheckGarantyModal,
  [NegotiationStateType.Negotiation]: NegotiationModal,
  [NegotiationStateType.Adp]: AdpModal,
}

const Negotiation = () => {
  const { data, loading } = useNegotiationTransparencyCardQuery()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedStep, setSelectedStep] = useState<NegotiationStateType>(STEPS[0])
  const [isOnError, setIsOnError] = useState<boolean | undefined>(false)

  const trackAction = useTracking()

  const { hasComortgagor } = useUser()

  useEffect(() => {
    trackAction('NEGOTIATION_DASHBOARD_PAGE_VIEWED', { version: 'transparency' })
  }, [])

  if (loading) {
    return <NegotiationPlaceholder />
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleClickAdvisor = () => {
    trackAction('NEGOTIATION_DASHBOARD_BANK_TIMELINE_STEP_MODAL_ERROR_ADVISOR_CLICKED', { step: selectedStep })
  }

  const handleClickInfo = ({ step, error }: { step: NegotiationStateType; error?: boolean }) => {
    setSelectedStep(step)
    setIsDialogOpen(true)

    setIsOnError(error)

    trackAction('NEGOTIATION_DASHBOARD_BANK_TIMELINE_STEP_MODAL_OPENED', { error: !!error, step })
  }

  const handleClickLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    trackAction('NEGOTIATION_DASHBOARD_BANK_TIMELINE_STEP_MODAL_LINK_CLICKED', {
      step: selectedStep,
      url: event.currentTarget.href,
    })
  }

  const negotiations = ensureNotNil(data?.negotiations)
  const mortgagorMandateSignDate = data?.deal?.mandateStatus?.signatures?.[0]?.signedAt
  const comortgagorMandateSignDate = hasComortgagor ? data?.deal?.mandateStatus?.signatures?.[1]?.signedAt : null

  const liftDate = data?.project?.purchase?.conditions_precedent_lift_date
  const mandateDate = getLastMandateDate({ mortgagorMandateSignDate, comortgagorMandateSignDate })

  const banks = negotiations.map(({ bank, events, state: { status, type } }) => {
    const bankPicturePath = getBankLogo({ format: 'squared', slug: bank })
    const { label: bankName } = BANKS.find(({ value }: { value: string }) => value === bank) ?? { label: 'unknown' }

    const index = STEPS.findIndex(step => step === type)

    const steps: NegotiationStepBankProps[] = [
      ...STEPS.slice(0, index).map(step => ({
        get label() {
          const event = events.find(({ type }) => type === step)

          if (event) {
            return temporal(event.dateTime).format('DD/MM/YYYY')
          }
        },
        status: Status.Done,
        title: LABELS[step],
        onClickInfo: handleClickInfo.bind(null, { step }),
      })),

      {
        get label() {
          if (status === NegotiationStateStatus.Failed) {
            return type === NegotiationStateType.Sent ? '' : 'Refus'
          }

          if (status === NegotiationStateStatus.InProgress) {
            return 'En cours'
          }
        },
        get status() {
          if (status === NegotiationStateStatus.Failed) {
            return Status.Failed
          }

          return Status.Current
        },
        title: LABELS[STEPS[index]],
        onClickInfo: handleClickInfo.bind(null, {
          step: STEPS[index],
          error: status === NegotiationStateStatus.Failed,
        }),
      },

      ...STEPS.slice(index + 1).map(step => ({
        get status() {
          if (status === NegotiationStateStatus.Failed) {
            return Status.Canceled
          }

          return Status.Next
        },
        title: LABELS[step],
        onClickInfo: handleClickInfo.bind(null, { step }),
      })),
    ]

    const handleToggle = (isExpanded: boolean) => {
      trackAction('NEGOTIATION_DASHBOARD_BANK_TIMELINE_STATE_CHANGED', { bankName, expanded: isExpanded })
    }

    return { bankName, bankPicturePath, steps, onToggle: handleToggle, isDefaultExpanded: false }
  })

  const props: MainSectionNegotiationsProps = {
    mandateSignatureDate: temporal(mandateDate).format('DD/MM/YYYY'),
    conditionPrecedentLiftDate: temporal(liftDate).format('DD/MM/YYYY'),
    negotiationStepBankContainerProps: {
      negotiationStepSimpleProps: {
        status: StepSimpleStatus.Current,
        title: 'Négociations bancaires',
      },
      banks,
    },
    npsComponent: Nps,
  }

  return (
    <>
      <Dialog isOpen={isDialogOpen} onRequestClose={handleCloseDialog}>
        {createElement(CONTENT_DIALOG[selectedStep], {
          onClose: handleCloseDialog,
          onClickAdvisor: handleClickAdvisor,
          onClickLink: handleClickLink,
          title: LABELS[selectedStep],
          isOnError,
          slug: selectedStep,
        })}
      </Dialog>

      <MainSectionNegotiations {...props} />
    </>
  )
}

export const config: Config = {
  component: Negotiation,
  name: 'negotiation',
}
