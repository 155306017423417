import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

const AVATAR_WIDTH = g(7)
export const Page = styled.div`
  margin-top: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`

export const Title = styled.div`
  margin-bottom: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 0 ${g(4)} ${AVATAR_WIDTH};
  }
`

export const BubbleChat = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 80%;
  }
`

export const Buttons = styled.div`
  margin-top: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-end;
    display: flex;
    margin-left: ${AVATAR_WIDTH};
  }
`

export const Button = styled.div`
  margin-bottom: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-right: ${g(2)};
  }
`
