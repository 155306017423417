import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import ProjectRow from '@pretto/bricks/shared/components/ProjectRow'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const OfferDetailsCard = ({ description, rows }) => (
  <Card variant="neutral-1-20" format="line">
    <Content>
      <SubHeading size="large">Détails de l’offre</SubHeading>
      {description && <S.Description>{description}</S.Description>}
    </Content>
    <Divider />
    {rows.map((lines, i) => (
      <Fragment key={i}>
        <Content>
          {lines.map((row, index) => (
            <S.Row key={index}>
              <ProjectRow {...row} />
            </S.Row>
          ))}
        </Content>
        {i + 1 < rows.length && <Divider />}
      </Fragment>
    ))}
  </Card>
)

OfferDetailsCard.propTypes = {
  /** Description of details card. */
  description: PropTypes.node,
  /** Details list, separated with dividers. Each row is a [ProjectRow](#!/ProjectRow). */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
}

export default memo(OfferDetailsCard)
