import OfferCard from '@pretto/bricks/app/offer/components/OfferCard'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferCards = ({ cards }) => (
  <S.Cards>
    {cards.map((card, index) => (
      <S.Card key={index}>
        <OfferCard {...card} isRecommended={index === 0}></OfferCard>
      </S.Card>
    ))}
  </S.Cards>
)

OfferCards.propTypes = {
  /** Array of [OfferCard](#!/OfferCard)s. */
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(OfferCards)
