import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Stages = ({ items, active }) => (
  <S.Component length={items.length}>
    <S.ExtraDecoration isActive={active >= 0} isFirst></S.ExtraDecoration>
    {items.map((item, i) => (
      <S.Decoration
        key={i}
        isActive={i <= active}
        position={i + 2}
        length={items.length}
        nextIsActive={i + 1 <= active || active >= items.length - 1}
      >
        <S.Bullet />
      </S.Decoration>
    ))}
    <S.ExtraDecoration isActive={active >= items.length - 1}></S.ExtraDecoration>
    {items.map((item, i) => (
      <S.Stage key={i} isActive={i <= active} position={i + 2}>
        {item}
      </S.Stage>
    ))}
  </S.Component>
)

Stages.propTypes = {
  /** Current stage. Starts at 0. */
  active: PropTypes.number,
  /** Different stages. */
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Stages.defaultProps = { direction: 'vertical' }

export default memo(Stages)
