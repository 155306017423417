import Responsive from '@pretto/bricks/components/utility/Responsive'

import PropTypes from 'prop-types'

import * as S from './styles'

const RangeContent = ({ range }) => (
  <S.MainValue>
    <Responsive min="tablet">
      {range[0]} - {range[1]}
    </Responsive>
    <Responsive max="tablet">
      {range[0]} -
      <br />
      {range[1]}
    </Responsive>
  </S.MainValue>
)

RangeContent.propTypes = {
  range: PropTypes.array,
}

export default RangeContent
