import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import * as typo from '@pretto/bricks/core/typography'

import { Spinner } from '@pretto/zen/atoms/loaders/Spinner/Spinner'
import { Snackbar } from '@pretto/zen/main/banners/Snackbar/Snackbar'
import { Content as SnackbarContent } from '@pretto/zen/main/banners/Snackbar/Snackbar.styles'

import { formatLongDateEN, formatLongDateFR } from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/timeFormatter'
import { LOCAL_AGENDA_VISIBLE_NAME } from '@pretto/app/src/Simulation/config/agenda'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { MUTUALIZED_AGENDA } from '@pretto/app/src/apollo'
import { AdvisorTypes } from '@pretto/app/src/config/advisor'
import { removeItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useQuery } from '@apollo/client'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Slot } from './components/Calendar/Calendar'
import { Calendar } from './containers/Calendar'
import { Subscribe } from './containers/Subscribe'

interface AlertProps {
  in: boolean
  children: ReactNode
}

export interface BookingInfosParams {
  isLoading: boolean
  name?: string
  start?: string
}

interface MutualizedAgendaProps {
  isOpen: boolean
  onBookingInfos?: (bookingInfos: BookingInfosParams) => void
  onClose?: () => void
  onContinue?: () => void
}

export interface AgendaAdvisor {
  id: string
  firstName: string
  lastName: string
  pictureLink: string
  isVisio: boolean
  role: AdvisorTypes
}

enum Steps {
  Agenda = 'agenda',
  Subscribe = 'subscribe',
}

export const MutualizedAgenda: React.FC<MutualizedAgendaProps> = ({ isOpen, onBookingInfos, onClose, onContinue }) => {
  const [step, setStep] = useState<Steps>(Steps.Agenda)
  const [selectedSlot, setSelectedSlot] = useState<Slot>()
  const { data, loading } = useQuery(MUTUALIZED_AGENDA)
  const [alert, setAlert] = useState<ReactNode>('')
  const [isShown, setIsShown] = useState<boolean>(!!alert)

  const trackAction = useTracking()
  const hasAlreadyBeenViewed = useRef(false)
  const { typology, isEnglishUser } = useUser()

  useEffect(() => {
    if (alert) {
      setIsShown(true)
      const timeout = setTimeout(() => {
        setIsShown(false)
      }, 5000)

      return () => clearTimeout(timeout)
    }
  }, [alert])

  useEffect(() => {
    if (data?.mutualizedAgenda && isOpen && !hasAlreadyBeenViewed.current) {
      hasAlreadyBeenViewed.current = true
      trackAction('APPOINTMENT_MUTUALIZED_AGENDA_VIEWED', {
        slotsNumber: data?.mutualizedAgenda.length,
      })
    }
  }, [data, isOpen])

  const handleConfirmAppointment = (slot?: Slot) => {
    if (!slot) {
      return
    }

    const { utcDate, ...slotProps } = slot
    const selected = {
      ...slotProps,
      utcDate,
      label: isEnglishUser ? formatLongDateEN(utcDate) : formatLongDateFR(utcDate),
    }

    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CHOSEN')
    setSelectedSlot(selected)
    setStep(Steps.Subscribe)
  }

  const handleCancelAppointment = () => {
    setStep(Steps.Agenda)
  }

  const handleAlert = (alert: ReactNode) => {
    setAlert(alert)
  }

  const handleClose = () => {
    removeItem(LOCAL_AGENDA_VISIBLE_NAME)
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CLOSED')
    if (onClose) {
      onClose()
    }
    handleCancelAppointment()
  }

  const handleContinue = () => {
    removeItem(LOCAL_AGENDA_VISIBLE_NAME)

    if (typology === 'client') {
      return handleClose()
    }

    if (onContinue) {
      onContinue()
    }
  }

  const bottomCTA = {
    [Steps.Agenda]: {
      label: t('mutualizedAgenda.skipAppointment'),
      onClick: handleContinue,
    },
    [Steps.Subscribe]: {
      label: t('mutualizedAgenda.backToResults'),
      onClick: handleClose,
    },
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!!alert && <Alert in={isShown}>{alert}</Alert>}
          <Title>{t('mutualizedAgenda.title')}</Title>
          {step === Steps.Agenda && <Calendar onBooked={handleConfirmAppointment} slots={data?.mutualizedAgenda} />}
          {step === Steps.Subscribe && (
            <Subscribe
              onAlert={handleAlert}
              onClose={handleClose}
              onBookingInfos={onBookingInfos}
              onModify={handleCancelAppointment}
              selectedSlot={selectedSlot}
            />
          )}
          <ContinueWithoutAppointment {...bottomCTA[step]} />
        </>
      )}
    </Modal>
  )
}

const Modal = styled(Dialog)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  padding: ${g(2)} ${g(2.5)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(5)} ${g(13)};
  }
`

const Loader = styled(Spinner)`
  margin: auto;
`

const Alert = styled(Snackbar).attrs<AlertProps>({ status: 'alert' })`
  width: 100%;
  margin: 0;
  margin-bottom: ${g(2)};
  padding: 0;

  & ${SnackbarContent} {
    justify-content: flex-start;
  }
`

const Title = styled.h1`
  ${typo.heading32};
  margin-bottom: ${g(1)};
`

const ContinueWithoutAppointment = styled(ButtonFeature)`
  align-self: center;
  margin-top: ${g(4)};
`
