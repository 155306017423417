import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Title = styled.div`
  margin-bottom: ${g(2)};
  white-space: nowrap;
`

export const Description = styled.div`
  margin-bottom: ${g(2)};
`

export const Card = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: relative;
    height: 100%;
  }
`

export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(7)};
  }
`
export const Button = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    bottom: ${g(3)};
    left: ${g(3)};
  }
`
