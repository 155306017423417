import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import CheckList from '@pretto/bricks/components/lists-tables/CheckList'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferListCard = ({ title, items }) => (
  <Card format="line" variant="neutral-1-20">
    <Content>
      <S.Title>
        <SubHeading size="large">{title}</SubHeading>
      </S.Title>
      <CheckList items={items} />
    </Content>
  </Card>
)

OfferListCard.propTypes = {
  /** Items of list. Cf. [CheckList](#!CheckList) component. */
  items: PropTypes.array.isRequired,
  /** Title of card. */
  title: PropTypes.string.isRequired,
}

export default memo(OfferListCard)
