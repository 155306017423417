import Card from '@pretto/bricks/components/cards/Card'
import Tag from '@pretto/bricks/components/information/Tag'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import * as S from './styles'

const OfferCard = ({ isRecommended, isSelected, onClick, bank, rate }) => {
  const [isHover, onHover] = useState(false)
  const handleHover = isHover => onHover(isHover)
  const cardProps = { zDepth: isSelected ? 2 : 0 }
  if (isHover) Object.assign(cardProps, { variant: 'accent-1' })
  return (
    <S.Card onClick={onClick}>
      <Card
        format={['line', 'small-radius']}
        variant="neutral-1-20"
        zDepth={isRecommended ? 2 : 0}
        onHover={handleHover}
        {...cardProps}
      >
        <Content size="small">
          {isRecommended && (
            <S.Recommended>
              <Tag variant="accent-1" size="small">
                recommandée
              </Tag>
            </S.Recommended>
          )}
          <S.Title>
            <S.Image>
              <img src={`https://res.cloudinary.com/pretto-fr/image/upload/c_fill,h_80,w_80${bank}`} alt={bank} />
            </S.Image>
            <Heading size={isRecommended ? 'medium' : 'small'} variant={isRecommended ? 'accent-1' : 'neutral-1'}>
              {rate.toLocaleString('fr', { minimumFractionDigits: 2 })} %
            </Heading>
          </S.Title>
        </Content>
      </Card>
    </S.Card>
  )
}

OfferCard.propTypes = {
  /** Whether if offer is recommended or not. Applies different styles. */
  isRecommended: PropTypes.bool,
  /** Whether if offer is selected or not. Applies different styles. */
  isSelected: PropTypes.bool,
  /** onClick function triggered when the component is clicked (from anywhere). */
  onClick: PropTypes.func,
  /** Bank relative url from cloudinary. */
  bank: PropTypes.string.isRequired,
  /** Rate of offer. */
  rate: PropTypes.number.isRequired,
}

export default memo(OfferCard)
