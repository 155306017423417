import { MOBILE_NAVBAR_HEIGHT } from '@pretto/bricks/components/bars/NavBar/styles'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import { Layout } from '@pretto/app/src/onboarding/components/Layout/Layout'
import { InfoCircle } from '@pretto/picto'

import styled from 'styled-components'

export const MainContainer = styled(Layout)`
  max-height: calc(100vh - ${MOBILE_NAVBAR_HEIGHT});
  background-color: ${({ theme }) => theme.colors.white};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    max-height: ${g(98)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    max-height: ${g(78)};
  }
`

export const ContentContainer = styled.div`
  height: 100%;
  ${grid(4, { isLeftAligned: true, isRightAligned: true })};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${grid(6, { isLeftAligned: true, isRightAligned: true })};
    row-gap: 0;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const LeftContainer = styled.div`
  display: none;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: #004338;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    ${column(6)};
    height: ${g(30)};
    background-image: url(${getCloudinaryUrl(`v1670509270/pretto-ui/UploadDocumentTablet.gif`)});
    border-top-left-radius: ${g(1)};
    border-top-right-radius: ${g(1)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    background-color: #023b37;
    ${column(4)};
    height: 100%;
    background-image: url(${getCloudinaryUrl(`v1670509270/pretto-ui/UploadDocumentDesktop.gif`)});
    background-position: right center;
    border-top-left-radius: ${g(1)};
    border-bottom-left-radius: ${g(1)};
    border-top-right-radius: ${g(0)};
  }
`

export const RightContainer = styled.div`
  ${column(4)};
  height: 100%;
  position: relative;
  background-color: white;
  padding: ${g(3)} ${g(2, 4)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(6)};
    padding: ${g(4)} ${g(5)} ${g(5)} ${g(5)};
    height: ${g(68)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
    padding: ${g(5)} ${g(4)};
    height: 100%;
  }
`

export const Title = styled.div`
  ${typo.heading32}
`

export const StyledBanner = styled(Banner).attrs({ picto: InfoCircle })`
  margin-top: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(7)};
    width: calc(100% - ${g(7)});
  }
`

export const FooterContainer = styled.div`
  margin-top: ${g(2)};
  position: fixed;
  bottom: ${g(2)};
  left: ${g(2, 4)};
  right: ${g(2, 4)};

  & > button {
    width: 100%;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: flex-end;
    bottom: ${g(5)};
    right: ${g(5)};

    & > button {
      width: auto;
    }
  }
`
