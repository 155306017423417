import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Card = styled.div`
  display: flex;
`

export const Recommended = styled.div`
  margin-bottom: ${g(2)};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const Image = styled.div`
  margin-right: ${g(1)};
  width: ${g(5)};
  height: ${g(5)};
  border-radius: 2px;
  overflow: hidden;

  img {
    width: 100%;
  }
`

export const Rate = styled.div``
