import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const ACTION_URL = '/offer'

const Offer: React.FC<CardProps> = ({ dashboardData, defaultComponent: Component, onActionClick }) => {
  const handleActionClick = () => {
    onActionClick(ACTION_URL)
  }

  if (dashboardData?.deal?.agreement !== 'approved') {
    return (
      <Component
        actionLabel="Voir les offres"
        actionUrl={ACTION_URL}
        description="Faites votre choix parmi les propositions négociées."
        onActionClick={handleActionClick}
        tag="Étape en cours"
        title="Choisissez votre offre"
      />
    )
  }

  return (
    <Component
      actionLabel="Consulter"
      actionUrl={ACTION_URL}
      description="Consultez les offres proposées par Pretto."
      onActionClick={handleActionClick}
      tag="Étape en cours"
      title="Rappel de l'offre que vous avez choisie"
    />
  )
}

export const config: Config = {
  condition: dashboardData =>
    !!dashboardData?.deal?.agreement && ['approved', 'promoted'].includes(dashboardData.deal.agreement),
  component: Offer,
  name: 'offer',
}
