import BookingAppointmentSummary from '@pretto/bricks/app/booking/components/BookingAppointmentSummary'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Heading from '@pretto/bricks/components/typography/Heading'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BookingSummaryPage = ({ date, isDialogOpen, isMutating, onConfirm, onDialogClose, ...props }) => (
  <S.Page>
    <S.Header>
      <Wrapper>
        <Responsive min="tablet">
          <S.Title>
            <Heading>Votre rendez-vous</Heading>
          </S.Title>
        </Responsive>

        <div>Vous avez un rendez-vous programmé avec votre expert. Voici les détails de ce rendez-vous.</div>
      </Wrapper>
    </S.Header>

    <Wrapper full="tablet">
      <BookingAppointmentSummary {...props} date={date} />
    </Wrapper>

    <Dialog isOpen={isDialogOpen} onRequestClose={onDialogClose}>
      <DialogTemplate
        buttonProps={{
          children: 'Confirmer',
          isDisabled: isMutating,
          isLoading: isMutating,
          onClick: onConfirm,
        }}
        navbarProps={{
          iconLeft: TimesBold,
          iconLeftAction: onDialogClose,
          title: "Confirmer l'annulation",
        }}
      >
        <S.DialogContent>Souhaitez-vous annuler votre rendez-vous du {date} ?</S.DialogContent>
      </DialogTemplate>
    </Dialog>
  </S.Page>
)

BookingSummaryPage.propTypes = {
  /** Human-written date of the the appointment. */
  date: PropTypes.string.isRequired,
  /** Whether or not the cancel dialog is opened. */
  isDialogOpen: PropTypes.bool.isRequired,
  /** Whether or not the cancel dialog should be displaying a loading state. */
  isMutating: PropTypes.bool.isRequired,
  /** Triggered whenever the cancel dialog button is pressed. */
  onConfirm: PropTypes.func.isRequired,
  /** Triggered whenever the dialog close actions are being triggered. */
  onDialogClose: PropTypes.func.isRequired,
}

export default memo(BookingSummaryPage)
