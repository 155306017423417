import btoa from 'btoa-lite'

import { getBookingDuration } from './getBookingDuration'

const useAvailabilities = (constraints, appointmentKind, advisorEmail) => {
  const { bufferDuration, kind, meetDuration } = getBookingDuration(appointmentKind)

  const defaultOptions = {
    ...{ buffer: { pre: bufferDuration } },
    constraints: [
      ...constraints.absences.map(block_period => ({ block_period })),
      ...constraints.allowPeriods.map(allow_period => ({ allow_period })),
      ...constraints.availabilities,
    ],
    length: meetDuration,
  }

  const headers = new Headers()

  headers.append('Authorization', `Basic ${btoa(`:${process.env.TIMEKIT_API_KEY}`)}`)
  headers.append('Content-Type', 'application/json')

  // Get all availabilities between to temporal datetimes
  const getAvailabilitiesBetween = async (fromDateTime, toDateTime) => {
    const body = {
      ...defaultOptions,
      from: fromDateTime.toISOString(),
      to: toDateTime.toISOString(),
    }

    const promise = await fetch(
      `https://pretto-timekit-w3tcfzw6jq-ew.a.run.app/calendar/${advisorEmail}/availabilities/slots`,
      {
        body: JSON.stringify(body),
        headers,
        method: 'POST',
      }
    )

    const data = await promise.json()

    return data
  }

  // Get all availabilities contained within a specified timeframe (default to 5 days).
  // It will try until at least one availability has been found and as many times it is necessary,
  // without exceeded the maxRetries parameter (default to 5).
  const getAvailabilitiesWithinAndRepeat = async ({ daysWithin = 5, fromDateTime, maxRetries = 5, retry = 0 }) => {
    if (retry > maxRetries) {
      return []
    }

    const toDateTime = fromDateTime.add(daysWithin, 'days')

    const availabilities = await getAvailabilitiesBetween(fromDateTime, toDateTime)

    // If no results, retry to get next batch of availabilities
    // until reaches MAX_RETRIES
    if (!availabilities.length) {
      return getAvailabilitiesWithinAndRepeat({ daysWithin, fromDateTime: toDateTime, maxRetries, retry: retry + 1 })
    }

    return availabilities
  }

  const getMinimumAvailabilitiesFrom = async ({
    fromDateTime,
    loadedDays = 0,
    maxRetries = 10,
    minimumDays,
    previous = [],
    retry = 0,
  }) => {
    if (loadedDays >= minimumDays) {
      return previous
    }

    // If too many retries, just return all
    // previous results
    if (retry > maxRetries) {
      return previous
    }

    const toDateTime = fromDateTime.endOf('day')

    const availabilities = await getAvailabilitiesBetween(fromDateTime, toDateTime)

    // If not, try again
    return getMinimumAvailabilitiesFrom({
      fromDateTime: fromDateTime.add(1, 'day').startOf('day'),
      loadedDays: loadedDays + Number(availabilities.length > 0),
      maxRetries,
      minimumDays,
      previous: [...previous, ...availabilities],
      retry: retry + 1,
    })
  }

  return [getAvailabilitiesBetween, getAvailabilitiesWithinAndRepeat, getMinimumAvailabilitiesFrom, kind]
}

export default useAvailabilities
