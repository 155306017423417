import * as v from '@pretto/app/src/Sentences/v2/lib/validators/context'
import type { ContextValidator } from '@pretto/app/src/Sentences/v2/types/validators'
import { t } from '@pretto/app/src/lib/i18n'

import { allPass } from 'ramda'
import anyPass from 'ramda/src/anyPass'

interface Step {
  label: string
  // routes on where the step is accessible, the first valid route will be used as path
  accessibleRoutesPattern: string[]
  // route on where the step is active
  activeRoutesPattern: string[]
  // when the step is visible
  isVisible?: ContextValidator
}

export interface Section {
  title?: string
  steps?: Step[]
}

export const sections: Section[] = [
  {
    title: t('timeline.sections.introduction.title'),
    steps: [
      {
        label: t('timeline.sections.introduction.steps.project'),
        accessibleRoutesPattern: [
          '/project/(capacity|purchase|renegotiation)/introduction/structure',
          '/project/introduction/others',
          '/project/maturity',
        ],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)?/introduction'],
      },
    ],
  },
  {
    title: t('timeline.sections.mortgagor.title'),
    steps: [
      {
        label: t('timeline.sections.mortgagor.steps.situationPersonal'),
        accessibleRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/lodging/(ageChildren)?'],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/lodging'],
      },
      {
        label: t('timeline.sections.mortgagor.steps.loan'),
        accessibleRoutesPattern: ['/project/renegotiation/loan/bank'],
        activeRoutesPattern: ['/project/renegotiation/loan'],
        isVisible: v.isRenegotiation,
      },
      {
        label: t('timeline.sections.mortgagor.steps.situationProfessional'),
        accessibleRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/occupation/sector'],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/occupation'],
      },
      {
        label: t('timeline.sections.mortgagor.steps.incomes'),
        accessibleRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/income/salary'],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/income'],
      },
    ],
  },
  {
    title: t('timeline.sections.comortgagor.title'),
    steps: [
      {
        label: t('timeline.sections.comortgagor.steps.situationPersonal'),
        accessibleRoutesPattern: ['/project/(capacity|purchase|renegotiation)/lodging-comortgagor/address'],
        activeRoutesPattern: ['/project/(capacity|purchase|renegotiation)/lodging-comortgagor'],
        isVisible: allPass([
          v.hasSimulationType,
          anyPass([v.isCapacity, v.isPurchase, v.isRenegotiation]),
          v.isNotSolo,
          v.doesNotLiveTogether,
        ]),
      },
      {
        label: t('timeline.sections.comortgagor.steps.situationProfessional'),
        accessibleRoutesPattern: ['/project/(capacity|purchase|renegotiation)/occupation-comortgagor/sector'],
        activeRoutesPattern: ['/project/(capacity|purchase|renegotiation)/occupation-comortgagor'],
        isVisible: allPass([
          v.hasSimulationType,
          anyPass([v.isCapacity, v.isPurchase, v.isRenegotiation]),
          v.isNotSolo,
        ]),
      },
      {
        label: t('timeline.sections.comortgagor.steps.incomes'),
        accessibleRoutesPattern: ['/project/(capacity|purchase|renegotiation)/income-comortgagor/salary'],
        activeRoutesPattern: ['/project/(capacity|purchase|renegotiation)/income-comortgagor'],
        isVisible: v.isNotSolo,
      },
    ],
  },
  {
    steps: [
      {
        label: t('timeline.sections.mortgagor.steps.sale'),
        accessibleRoutesPattern: ['/project/(capacity|purchase)/sale/estimatedPrice'],
        activeRoutesPattern: ['/project/(capacity|purchase)/sale'],
        isVisible: v.isForSale,
      },
      {
        label: t('timeline.sections.end.steps.buyout'),
        accessibleRoutesPattern: ['/project/buyout/buyout'],
        activeRoutesPattern: ['/project/buyout/buyout'],
        isVisible: v.isBuyout,
      },
      {
        label: t('timeline.sections.end.steps.charges'),
        accessibleRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/credits/credits'],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase|renegotiation)/credits'],
      },
      {
        label: t('timeline.sections.end.steps.good'),
        accessibleRoutesPattern: [
          '/project/purchase/property/good',
          '/project/purchase/property/construction',
          '/project/purchase/property/landConstruction',
        ],
        activeRoutesPattern: ['/project/purchase/property'],
        isVisible: allPass([
          v.hasSimulationType,
          v.isPurchase,
          anyPass([v.isOldNewOrVEFA, v.isPropertyConstruction, v.isPropertyLandConstruction]),
        ]),
      },
      {
        label: t('timeline.sections.end.steps.good'),
        accessibleRoutesPattern: ['/project/renegotiation/property-renegotiation/estimatedPrice'],
        activeRoutesPattern: ['/project/renegotiation/property-renegotiation'],
        isVisible: allPass([v.hasSimulationType, v.isRenegotiation]),
      },
      {
        label: t('timeline.sections.mortgagor.steps.ptz'),
        accessibleRoutesPattern: ['/project/(capacity|purchase)/ptz/beenOwner'],
        activeRoutesPattern: ['/project/(capacity|purchase)/ptz'],
        isVisible: allPass([v.hasSimulationType, anyPass([v.isCapacity, v.isPurchase]), v.hasPTZ]),
      },
      {
        label: t('timeline.sections.end.steps.contribution'),
        accessibleRoutesPattern: ['/project/(buyout|capacity|purchase)/contribution/contribution'],
        activeRoutesPattern: ['/project/(buyout|capacity|purchase)/contribution'],
        isVisible: allPass([v.hasSimulationType, anyPass([v.isBuyout, v.isCapacity, v.isPurchase])]),
      },
      {
        label: t('timeline.sections.end.steps.competition'),
        accessibleRoutesPattern: ['/project/purchase/competition/hasCompetition'],
        activeRoutesPattern: ['/project/purchase/competition'],
        isVisible: allPass([v.hasSimulationType, v.isPurchase]),
      },
      {
        label: t('timeline.sections.end.steps.rateAlert'),
        accessibleRoutesPattern: ['/project/(purchase|renegotiation)/rate-alert/wantsRateAlert'],
        activeRoutesPattern: ['/project/(purchase|renegotiation)/rate-alert'],
        isVisible: allPass([v.hasSimulationType, anyPass([v.isPurchase, v.isRenegotiation]), v.isVisibleRateAlert]),
      },
      {
        label: t('timeline.sections.end.steps.propertySearch'),
        accessibleRoutesPattern: ['/project/capacity/property-search/localisations'],
        activeRoutesPattern: ['/project/capacity/property-search'],
        isVisible: allPass([v.hasSimulationType, v.isCapacity, v.isVisiblePropertySearch]),
      },
    ],
  },
]
