import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferChooseCard = ({ onClick, isRecommended }) => (
  <Card variant="accent-1-l">
    <Content>
      <SubHeading variant="accent-1">Choisir cette offre</SubHeading>
      <S.Content>
        <Text size="small">
          {isRecommended
            ? 'Choisissez cette offre pour confirmer votre demande de prêt'
            : 'Nous pensons que ce n’est pas la meilleure offre pour vous, parlons-en !'}
        </Text>
      </S.Content>
      <S.ButtonWrapper>
        <ButtonLegacy size="large" variant="accent-1" onClick={onClick}>
          {isRecommended ? 'Choisir' : 'Prendre RDV'}
        </ButtonLegacy>
      </S.ButtonWrapper>
    </Content>
  </Card>
)

OfferChooseCard.propTypes = {
  /** onClick function, triggered when button is clicked. */
  onClick: PropTypes.func.isRequired,
  /** Whether if offer is recommended or not. Changes content and button texts. */
  isRecommended: PropTypes.bool,
}

export default memo(OfferChooseCard)
