import OfferAppointmentCard from '@pretto/bricks/app/offer/components/OfferAppointmentCard'
import { OfferBankCriterias } from '@pretto/bricks/app/offer/components/OfferBankCriterias/OfferBankCriterias'
import OfferCards from '@pretto/bricks/app/offer/components/OfferCards'
import OfferChooseCard from '@pretto/bricks/app/offer/components/OfferChooseCard'
import OfferDetailsBank from '@pretto/bricks/app/offer/components/OfferDetailsBank'
import OfferDetailsCard from '@pretto/bricks/app/offer/components/OfferDetailsCard'
import OfferListCard from '@pretto/bricks/app/offer/components/OfferListCard'
import OfferNextStepsCard from '@pretto/bricks/app/offer/components/OfferNextStepsCard'
import OfferRecommendedCard from '@pretto/bricks/app/offer/components/OfferRecommendedCard'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'

import PropTypes from 'prop-types'
import { memo, useRef } from 'react'

import * as S from './styles'

const scrollToRef = (ref, isMobile) => {
  const top = isMobile ? 48 : 16
  window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - top })
}

const OfferPage = ({
  advisorAvatar,
  advisorName,
  agreement,
  benefits,
  cards,
  detailsDescription,
  description,
  emoji,
  isMobile,
  isRecommended,
  legal,
  offer,
  onChooseOfferButton,
  onChooseOfferCard,
  onMakeAppointment,
  title,
}) => {
  const scrollRef = useRef(null)
  const scrollToDetails = () => scrollToRef(scrollRef, isMobile)

  const offerDetailsBankProps = {
    bank: offer.bank,
    duration: offer.duration,
    isRecommended,
    onClick: scrollToDetails,
    payment: offer.payment,
    rate: offer.rate,
  }
  const offerChooseCardProps = {
    isRecommended,
    onClick: onChooseOfferCard,
  }
  const offerRecommendedCardProps = {
    advisorAvatar,
    benefits,
  }
  const offerDetailsCardProps = {
    description: detailsDescription,
    rows: offer.details,
  }
  const offerAppointmentCardProps = {
    advisor: advisorName,
    onMakeAppointment,
  }

  return (
    <S.Page>
      <S.IntroductionWrapper>
        <Wrapper>
          <S.Introduction>
            <ResponsiveSSR min="tablet">
              <S.Title>
                <S.Emoji>
                  <Emoji size="medium">{emoji}</Emoji>
                </S.Emoji>
                <Heading>{title}</Heading>
              </S.Title>
            </ResponsiveSSR>
            <div>{description}</div>
          </S.Introduction>
        </Wrapper>
      </S.IntroductionWrapper>
      <S.Body>
        <S.CardsWrapper>
          <S.Cards>
            <OfferCards cards={cards} />
          </S.Cards>
        </S.CardsWrapper>
        {isRecommended && agreement === 'promoted' && (
          <S.RecommendedTitleWrapper>
            <Wrapper>
              <S.RecommendedTitle>
                <S.Emoji>
                  <Emoji size="medium">:ok_hand:</Emoji>
                </S.Emoji>
                <SubHeading size="large">Nous vous recommandons cette offre</SubHeading>
              </S.RecommendedTitle>
            </Wrapper>
          </S.RecommendedTitleWrapper>
        )}
        <Wrapper full>
          <S.BodyDetails>
            <S.Bank>
              <S.BankDetails>
                <OfferDetailsBank {...offerDetailsBankProps} />
              </S.BankDetails>
            </S.Bank>
            {onChooseOfferCard ? (
              <S.ChooseCard>
                <OfferChooseCard {...offerChooseCardProps} />
              </S.ChooseCard>
            ) : (
              <ResponsiveSSR min="tablet">
                <Card variant="accent-1-l">
                  <Content>
                    <SubHeading variant="accent-1">Vous avez déjà choisi une offre</SubHeading>
                    <S.ContentCard>
                      Vous n’avez plus rien à faire ! Vous pouvez toujours consulter le détail des offres proposés.
                    </S.ContentCard>
                  </Content>
                </Card>
              </ResponsiveSSR>
            )}
            {isRecommended && (
              <S.Card>
                <OfferRecommendedCard {...offerRecommendedCardProps} />
              </S.Card>
            )}
            <S.Card ref={scrollRef}>
              <OfferDetailsCard {...offerDetailsCardProps} />
            </S.Card>
            <S.Card>
              <OfferBankCriterias {...offer.bankCriterias} />
            </S.Card>
            {offer.guarantees.items.length > 0 && (
              <S.Card>
                <OfferListCard {...offer.guarantees} />
              </S.Card>
            )}
            {offer.concessions.items.length > 0 && (
              <S.Card>
                <OfferListCard {...offer.concessions} />
              </S.Card>
            )}
            <S.ButtonWrapper>
              <ResponsiveSSR min="tablet">
                <S.Card noLine>
                  <S.Button>
                    {onChooseOfferButton && (
                      <ButtonLegacy onClick={onChooseOfferButton}>Choisir cette offre</ButtonLegacy>
                    )}
                  </S.Button>
                </S.Card>
              </ResponsiveSSR>
            </S.ButtonWrapper>
          </S.BodyDetails>
        </Wrapper>
      </S.Body>
      <Wrapper full>
        <S.Steps>
          <S.Card>
            <OfferAppointmentCard {...offerAppointmentCardProps} />
          </S.Card>
          <S.Card>
            <OfferNextStepsCard />
          </S.Card>
        </S.Steps>
      </Wrapper>
      <Wrapper>
        <S.Legal>
          <Text size="small">{legal}</Text>
        </S.Legal>
      </Wrapper>
    </S.Page>
  )
}

const ListType = PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
}).isRequired
OfferPage.propTypes = {
  advisorAvatar: PropTypes.element.isRequired,
  advisorName: PropTypes.string.isRequired,
  agreement: PropTypes.string,
  benefits: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      bank: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
      rate: PropTypes.number.isRequired,
    })
  ).isRequired,
  description: PropTypes.node.isRequired,
  detailsDescription: PropTypes.node.isRequired,
  emoji: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  isRecommended: PropTypes.bool,
  legal: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    bank: PropTypes.shape({
      description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    concessions: ListType,
    bankCriterias: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string,
        value: PropTypes.number.isRequired,
      })
    ).isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired.isRequired,
    duration: PropTypes.number.isRequired,
    guarantees: ListType,
    payment: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired,
  }).isRequired,
  onChooseOfferButton: PropTypes.func,
  onChooseOfferCard: PropTypes.func,
  onMakeAppointment: PropTypes.func,
  title: PropTypes.string.isRequired,
}
export default memo(OfferPage)
