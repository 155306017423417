import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Page = styled.div`
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
`
export const Body = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    background: ${({ theme }) => theme.legacy.colors.white.default};
  }
`

export const IntroductionWrapper = styled.div``

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${g(4)};
  margin-bottom: ${g(3)};
`

export const Emoji = styled.div`
  margin-right: ${g(2)};
`

export const Introduction = styled.div`
  padding: ${g(3)} 0;
`

export const CardsWrapper = styled.div`
  padding-bottom: ${g(1)};
  background: ${({ theme }) => theme.legacy.colors.white.default};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }
`
export const Cards = styled.div`
  overflow: auto;
  padding: ${g(2)};
  padding-bottom: ${g(3)};
`

export const BodyDetails = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-gap: ${g(2)} ${g(3)};
    margin: 0 ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: 0;
  }
`

export const RecommendedTitleWrapper = styled.div`
  background: ${({ theme }) => theme.legacy.colors.white.default};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
  }
`

export const RecommendedTitle = styled.div`
  align-items: flex-start;
  display: flex;

  @media screen and (min-width: ${breakpoints.mobileL}) {
    align-items: flex-end;
  }
`

export const Bank = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    grid-row: 1 / span 2;
  }
`
export const BankDetails = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    width: 100%;
  }
`

export const ChooseCard = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: sticky;
    top: ${g(3)};
    margin-bottom: ${g(13)};
  }
`

export const Card = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 1 / span 1;
    width: 100%;
    margin-bottom: 0;
  }
`

export const ButtonWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 1 / span 1;
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${g(5)};
`

export const Steps = styled.div`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    margin-right: ${g(3)};
    margin-left: ${g(3)};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    margin-right: 0;
    margin-left: 0;
  }

  ${Card} {
    background: ${({ theme }) => theme.legacy.colors.white.default};
    border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
    border-right: 0;
    border-left: 0;

    @media screen and (min-width: ${breakpoints.tablet}) {
      background: initial;
      border: 0;
      width: auto;
      margin: 0;

      & + ${Card} {
        flex: 1 1 auto;
        margin-left: ${g(3)};
      }
    }
  }
`

export const ContentCard = styled.div`
  margin-top: ${g(2)};
`

export const Legal = styled.div`
  padding: ${g(3)} 0 ${g(5)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: ${g(6)};
  }
`
