import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { hasDpe } from '@pretto/app/src/Sentences/v2/lib/validators/context'
import { t } from '@pretto/app/src/lib/i18n'
import { Dpe } from '@pretto/app/src/types/gateway/enums'

import isNil from 'lodash/isNil'

export const dpePage: React.FC = () => {
  const context = useSentences()
  const { dpe, setContext } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(dpe)))

  const handleClickDpe = (dpe: Dpe) => {
    setContext({ dpe })
    goToNextRoute()
  }

  const mapDpeValues = (values: Dpe[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: dpe === value,
        onClick: () => {
          handleClickDpe(value)
        },
        label: t(`sentences.property.dpe.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'dpe' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.dpe.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(dpe)}>
          {t(`sentences.property.dpe.values.${isNil(dpe) ? Dpe.A : dpe}`)}
        </Prompt>
        .
        {activeField === 0 && (
          <Choices
            choices={mapDpeValues(Object.values(Dpe))}
            events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'dpe' } }}
          />
        )}
        <Message>{t('sentences.tips.dpe')}</Message>
        {hasDpe(context) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
      </div>
    </TrackedView>
  )
}
