import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferDetailsBank = ({ bank: { description, name, slug }, duration, isRecommended, onClick, payment, rate }) => (
  <Card format="line" variant="neutral-1-20">
    <Content>
      <S.Details>
        <S.Image>
          <img src={`https://res.cloudinary.com/pretto-fr/image/upload/c_fill,w_144,h_144${slug}`} alt={name} />
        </S.Image>
        <S.Infos>
          <S.Rate>
            <Heading variant={isRecommended ? 'accent-1' : 'neutral-1'} size="large">
              {rate.toLocaleString('fr', { minimumFractionDigits: 2 })} %
            </Heading>
          </S.Rate>
          <S.Duration>{duration / 12} ans</S.Duration>
          <div>{Math.round(payment).toLocaleString('fr')} € par mois hors assurance</div>
        </S.Infos>
      </S.Details>
      <ButtonSquared onClick={onClick} variant="accent-1-l" textVariant="accent-1">
        Voir les détails
      </ButtonSquared>
    </Content>
    <Divider />
    <Content>
      <S.About>
        <SubHeading>À propos de {name}</SubHeading>
      </S.About>
      <Text size="small">{description}</Text>
    </Content>
  </Card>
)

OfferDetailsBank.propTypes = {
  /** Details of bank. */
  bank: PropTypes.shape({
    /** Bank's description. */
    description: PropTypes.string.isRequired,
    /** Bank's full name. */
    name: PropTypes.string.isRequired,
    /** Bank's relative path in cloudinary (/banks/slug_of_bank.png). */
    slug: PropTypes.string.isRequired,
  }).isRequired,
  /** Duration of loan. */
  duration: PropTypes.number.isRequired,
  /** Whether if this is the recommended offer or not. Changes the color of the rate. */
  isRecommended: PropTypes.bool,
  /** onClick function triggered when see details button is clicked. */
  onClick: PropTypes.func.isRequired,
  /** Payment of loan. */
  payment: PropTypes.number.isRequired,
  /** Rate of loan. */
  rate: PropTypes.number.isRequired,
}

export default memo(OfferDetailsBank)
