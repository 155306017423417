import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Appointment: React.FC<CardProps> = ({ defaultComponent: Component }) => (
  <Component
    description="Votre rencontrez votre nouvelle banque et signez l'ouverture de vos comptes. La banque traite votre demande de prêt en 2 ou 4 semaines."
    title="Rendez-vous à la banque"
  />
)

export const config: Config = {
  component: Appointment,
  name: 'appointment',
}
