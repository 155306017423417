import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Content = styled.div`
  margin: ${g(2)} 0 ${g(3)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
