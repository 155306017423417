import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import { Picto as PictoType } from '@pretto/picto'

import styled, { css } from 'styled-components'

type BannerType = 'success' | 'warning' | 'error' | 'info'

export interface BannerProps {
  children: React.ReactNode
  picto?: PictoType
  type: BannerType
}

interface BannerStylesProps {
  backgroundColor: string
  color: string
}

const BANNER_STYLE: Record<BannerType, BannerStylesProps> = {
  error: {
    backgroundColor: 'error2',
    color: 'error1',
  },
  info: {
    backgroundColor: 'neutral4',
    color: 'neutral1',
  },
  success: {
    backgroundColor: 'primary4',
    color: 'primary1',
  },
  warning: {
    backgroundColor: 'warning2',
    color: 'warning1',
  },
}

export const Banner = ({ children, picto, type = 'success', ...props }: BannerProps) => (
  <MainContainer $type={type} {...props}>
    {picto && <Picto $picto={picto} />}
    <Description>{children}</Description>
  </MainContainer>
)

interface MainContainerProps {
  $type: BannerProps['type']
}

interface PictoProps {
  $picto: PictoType
}

const MainContainer = styled.div<MainContainerProps>`
  width: 100%;
  border-radius: ${g(1)};
  padding: ${g(2)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${({ theme, $type }) => {
    const { backgroundColor, color } = BANNER_STYLE[$type]

    return css`
      background-color: ${theme.colors[backgroundColor]};
      color: ${theme.colors[color]};
    `
  }}
`

const Picto = styled.svg.attrs<PictoProps>(({ $picto }) => ({ as: $picto }))<PictoProps>`
  color: inherit;
  min-height: ${g(3)};
  min-width: ${g(3)};
  margin-right: ${g(2)};
`

const Description = styled.p`
  ${typo.bodyBook16};
  color: inherit;
  flex-grow: 1;

  > a {
    text-decoration: underline;
  }

  > button {
    color: inherit;
    font: inherit;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
`
