import CapacityErrorPage from '@pretto/bricks/app/capacity/pages/CapacityErrorPage'

import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { t } from '@pretto/app/src/lib/i18n'
import { MortgageProject } from '@pretto/app/src/types/Capacity'

import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import Header from '../../../SharedContainers/Header'
import regroupCredits from '../../../Simulation/lib/regroupCredits'
import { useUser } from '../../../User/Containers/UserProvider'
import * as queries from '../../../apollo'
import { getMalusFromEligibilityResults, getMalusFromSimulationResults } from '../../../lib/simulationMalus'
import { useTracking } from '../../../lib/tracking'

const FLOW = 'capacity'
const BASE_PROPERTY_PRICE = 50000

const ErrorPage: React.FC = () => {
  const client = useApolloClient()

  const { typology, isEnglishUser } = useUser()

  const { push } = useHistory()

  const [data, setData] = useState<MortgageProject | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useLoading(isLoading)

  const trackAction = useTracking()

  useEffect(() => {
    compute()
  }, [])

  useEffect(() => {
    if (!data) {
      return
    }

    const trackingName = 'CAPACITY_ERROR_PAGE_VIEWED'
    const trackingOptions = {
      capacity_error_regroup_credits_shown: data.regroupCredits,
      simulation_error_flags: data.malus,
      simulation_kind: 'capacity',
    }

    trackAction(trackingName, trackingOptions)
  }, [data])

  const compute = async () => {
    // First, get the project
    const {
      data: { project },
    } = await client.query({ query: queries.SIMULATION_PROJECT })

    const bridge = (project.purchase.bridge?.estimated_value ?? 0) - (project.purchase.bridge?.remaining_principal ?? 0)
    const contribution = project.contribution

    const isRegroupCreditsActive = regroupCredits(project)

    const projectOverride = {
      purchase: {
        property_price: BASE_PROPERTY_PRICE + contribution + bridge,
      },
    }

    const variables = { override: JSON.stringify(projectOverride) }
    const locale = isEnglishUser ? 'en' : 'fr'

    // Second, check the eligibility
    const {
      data: { eligibility },
    } = await client.query({ query: queries.ELIGIBILITY, variables })

    if (eligibility.length > 0) {
      const malus = getMalusFromEligibilityResults(eligibility, project, locale)

      const data = {
        malus,
        regroupCredits: isRegroupCreditsActive,
      }

      setData(data)
      setIsLoading(false)

      return
    }

    // Finally, check the simulation
    const {
      data: { simulation },
    } = await client.query({ query: queries.SIMULATION, variables })

    const malus = getMalusFromSimulationResults(simulation, project, locale)

    const data = {
      malus,
      regroupCredits: isRegroupCreditsActive,
    }

    setData(data)
    setIsLoading(false)
  }

  if (isLoading) {
    return null
  }

  const editLabel =
    typology === 'client'
      ? t('reduceAmbitionPage.optionsPage.editFolder')
      : t('reduceAmbitionPage.optionsPage.editProfile')
  const editPath = typology === 'client' ? '/application' : `/project/${FLOW}/introduction`

  const handleEditProfile = () => push(editPath)
  const handleEditClick = () => {
    trackAction('CAPACITY_ERROR_EDIT_BUTTON_CLICKED')
    push(editPath)
  }
  const handleReview = () => {
    trackAction('CAPACITY_ERROR_REVIEW_BUTTON_CLICKED')
  }

  const onMarketingCardClick = (slug: string) =>
    trackAction('CAPACITY_MARKETING_CARD_CLICKED', {
      capacity_marketing_card_origin: 'error',
      capacity_marketing_card_slug: slug,
    })

  const handleFaqClick = () => onMarketingCardClick('faq')

  const handleGuideClick = () => onMarketingCardClick('guide')

  const handleRegroupCreditsClick = () => trackAction('CAPACITY_ERROR_REGROUP_CREDITS_BUTTON_CLICKED')

  const props = {
    ...data,
    actions: [
      {
        icon: 'pen',
        label: editLabel,
        onClick: handleEditProfile,
      },
    ],
    editLabel,
    introductionPath: `/project/${FLOW}/introduction`,
    onEditClick: handleEditClick,
    onFaqClick: handleFaqClick,
    onGuideClick: handleGuideClick,
    onRegroupCreditsClick: handleRegroupCreditsClick,
    onReview: handleReview,
    showSidebar: typology !== 'client',
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <CapacityErrorPage {...props} />
    </>
  )
}

export default ErrorPage
