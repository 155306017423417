import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscribeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SubscribeQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id?: string | null, project_kind?: Types.ProjectKind | null, purchase?: { __typename?: 'Purchase', property_price?: number | null, land_price?: number | null, build_price?: number | null } | null } | null };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const SubscribeDocument = gql`
    query Subscribe {
  project {
    id
    project_kind
    purchase {
      property_price
      land_price
      build_price
    }
  }
}
    `;

/**
 * __useSubscribeQuery__
 *
 * To run a query within a React component, call `useSubscribeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeQuery(baseOptions?: Apollo.QueryHookOptions<SubscribeQuery, SubscribeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribeQuery, SubscribeQueryVariables>(SubscribeDocument, options);
      }
export function useSubscribeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribeQuery, SubscribeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribeQuery, SubscribeQueryVariables>(SubscribeDocument, options);
        }
export type SubscribeQueryHookResult = ReturnType<typeof useSubscribeQuery>;
export type SubscribeLazyQueryHookResult = ReturnType<typeof useSubscribeLazyQuery>;
export type SubscribeQueryResult = Apollo.QueryResult<SubscribeQuery, SubscribeQueryVariables>;