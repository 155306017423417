import Card from '@pretto/bricks/components/cards/Card'
import { g } from '@pretto/bricks/components/layout'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import * as typo from '@pretto/bricks/core/typography'

import { BulletBook } from '@pretto/picto'

import nl2br from 'react-nl2br'
import styled, { css } from 'styled-components'

interface OfferBankCriteriasProps {
  title: string
  items: Array<{
    label: string
    description: string | null
    value: number
  }>
}

export const OfferBankCriterias: React.FC<OfferBankCriteriasProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  items,
  ...props
}) => (
  <Card {...props} format="line" variant="neutral-1-20">
    <Content>
      <Title size="large">{title}</Title>

      <List>
        {items.map(({ label, value, description }) => (
          <ListItem key={label}>
            <ListItemBullet />

            <ListItemBody>
              <div>{label}</div>
              {description && <ListItemBodyDescription>{nl2br(description.trim())}</ListItemBodyDescription>}
            </ListItemBody>

            <ListItemValue $value={value}>{value}/5</ListItemValue>
          </ListItem>
        ))}
      </List>
    </Content>
  </Card>
)

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
`

const ListItemBullet = styled(BulletBook)`
  flex-shrink: 0;
  margin-right: ${g(1)};
`

const ListItemBody = styled.li`
  flex-grow: 1;
`

const ListItemBodyDescription = styled.p`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-right: ${g(4)};
`

interface ListItemValueProps {
  $value: number
}

const ListItemValue = styled.li<ListItemValueProps>`
  ${typo.bodyBold16};
  ${typo.isNumber};
  border-radius: ${g(1 / 2)};
  flex-shrink: 0;
  margin-left: auto;
  padding: 0 ${g(1)};
  ${({ $value }) => {
    if ($value >= 4) {
      return css`
        background-color: ${({ theme }) => theme.colors.primary4};
        color: ${({ theme }) => theme.colors.primary1};
      `
    }
    if ($value >= 2) {
      return css`
        background-color: ${({ theme }) => theme.colors.warning2};
        color: ${({ theme }) => theme.colors.warning1};
      `
    }
    return css`
      background-color: ${({ theme }) => theme.colors.error2};
      color: ${({ theme }) => theme.colors.error1};
    `
  }}
`

const Title = styled(SubHeading)`
  margin-bottom: ${g(4)};
`
