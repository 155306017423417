import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetDisplayLanguageMutationVariables = Types.Exact<{
  input: Types.SetDisplayLanguageInput;
}>;


export type SetDisplayLanguageMutation = { __typename?: 'Mutation', setDisplayLanguage: { __typename?: 'SetDisplayLanguageResponse', success?: boolean | null } };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const SetDisplayLanguageDocument = gql`
    mutation SetDisplayLanguage($input: SetDisplayLanguageInput!) {
  setDisplayLanguage(input: $input) {
    success
  }
}
    `;
export type SetDisplayLanguageMutationFn = Apollo.MutationFunction<SetDisplayLanguageMutation, SetDisplayLanguageMutationVariables>;

/**
 * __useSetDisplayLanguageMutation__
 *
 * To run a mutation, you first call `useSetDisplayLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDisplayLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDisplayLanguageMutation, { data, loading, error }] = useSetDisplayLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDisplayLanguageMutation(baseOptions?: Apollo.MutationHookOptions<SetDisplayLanguageMutation, SetDisplayLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDisplayLanguageMutation, SetDisplayLanguageMutationVariables>(SetDisplayLanguageDocument, options);
      }
export type SetDisplayLanguageMutationHookResult = ReturnType<typeof useSetDisplayLanguageMutation>;
export type SetDisplayLanguageMutationResult = Apollo.MutationResult<SetDisplayLanguageMutation>;
export type SetDisplayLanguageMutationOptions = Apollo.BaseMutationOptions<SetDisplayLanguageMutation, SetDisplayLanguageMutationVariables>;