export const DETAILS_LABELS = {
  franchise: ['Démarrage différé', 'mois'],
  insuranceCost: ['Coût de l’assurance', '€'],
  interests: ['Montant des intérêts', '€'],
  bridgeValue: ['Montant du prêt relais', '€'],
  amount: ['Montant emprunté', '€'],
  duration: ['Durée', 'ans'],
  rate: ['Taux d’intérêt nominal', '%'],
  taeg: ['TAEG', '%'],
  contribution: ['Apport', '€'],
  payment: ['Mensualité hors assurance', '€'],
  insurancePayment: ['Mensualité d’assurance', '€'],
  guaranty: ['Caution', '€'],
  guarantyKind: ['Type de caution', '', kind => GUARANTY_KIND_LABELS[kind]],
  applicationFees: ['Frais de dossier bancaire', '€'],
  brokerageFees: ['Frais Pretto', '€'],
  totalCost: ['Coût total', '€'],
  renegotiationSavings: ['Économies réalisées', '€'],
}
export const GUARANTY_KIND_LABELS = {
  credit_logement: 'Crédit logement',
  credit_logement_initio: 'Crédit logement initio',
  saccef: 'SACCEF',
  ippd: 'Inscription de Privilège de Prêteur de Deniers (IPPD)',
  casden: 'CASDEN Banque Populaire',
  camca: 'CAMCA',
  bred_habitat: 'Bred Habitat',
  mortgage: 'Prêt immobilier',
  xmines: 'X-Mines',
  cnp_axa: 'Cnp Axa',
}

export const BANK_CRITERIAS = [
  {
    descriptionKey: 'iraDescription',
    label: 'Exonération d’IRA',
    valueKey: 'scoringIra',
  },
  {
    descriptionKey: 'insuranceDescription',
    label: 'Rapport couverture prix de l’assurance',
    valueKey: 'scoringInsurance',
  },
  {
    descriptionKey: 'modularityDescription',
    label: 'Flexibilité de la modularité',
    valueKey: 'scoringModularity',
  },
  {
    descriptionKey: 'reportDeadlineDescription',
    label: 'Flexibilité de la suspension d’échéances',
    valueKey: 'scoringReportDeadline',
  },
  {
    descriptionKey: 'averageResponseTimeDescription',
    label: 'Délais de traitement',
    valueKey: 'scoringAverageResponseTime',
  },
  {
    descriptionKey: 'rseDescription',
    label: 'Impact environnemental',
    valueKey: 'scoringRse',
  },
  {
    descriptionKey: 'digitizationDescription',
    label: 'Digitalisation de l’expérience client',
    valueKey: 'scoringDigitization',
  },
  {
    descriptionKey: 'customerExperienceDescription',
    label: 'Satisfaction Client Pretto',
    valueKey: 'scoringCustomerExperience',
  },
  {
    descriptionKey: 'bankingCostDescription',
    label: 'Coût de la banque',
    valueKey: 'scoringBankingCost',
  },
]

export const LABELS = {
  franchise: 'Différé',
  externalInsurance: 'Assurance de prêt alternative à celle de la banque',
  // guarantees
  ptia: 'Décès /PTIA',
  itt: 'Incapacité temporaire de travail (ITT)',
  ipp: 'Invalidité permanente et partielle (IPP)',
  ipt: 'Invalidité permanente et totale (IPT)',
  job: 'Perte d’emploi',
  mno: 'Couverture MNO',
  // concessions
  savings: 'Transfert de l’épargne',
  wages: 'Domiciliation des salaires',
  rent: 'Domiciliation des revenus locatifs',
  mrh: 'Assurance multirisque habitation',
  group_insurance: 'Assurance groupe',
  insurance: 'Délégation interne',
  rent_insurance: 'Assurance loyers impayés',
  pno: 'Assurance propriétaire non occupant',
  child: 'Comptes enfants',
  life_insurance: 'Ouverture d’une assurance vie',
  credit_cards: 'Equipement cartes bancaires',
  shares: 'Parts sociales',
  pea: 'Ouverture d’un PEA',
  pel: 'Ouverture d’un PEL',
  opcvm: 'Placement OPCVM',
  repurchase: 'Rachat des crédits en cours',
  pro_accounts: 'Rapatriement des comptes professionels',
  client: 'Client de la banque',
  a: 'Ouverture d’un livret A',
}

export const TITLES = {
  main: 'Excellente nouvelle',
  details: 'Détails de l’offre',
  bankCriterias: 'Les caractéristiques de la banque',
  guarantees: 'Garanties d’assurance',
  concessions: 'Leviers de négociation',
}

export const DESCRIPTIONS = {
  main: 'Voici les meilleures offres issues de notre analyse du marché et de la comparaison d’un grand nombre de contrats de prêts immobiliers.',
  details:
    'Voici les détails de l’offre négociée par Pretto. La mensualité d’assurance moyenne et le TAEG sont temporaires et peuvent évoluer.',
  legal:
    '*Le coût définitif de l’assurance- emprunteur figurera dans l’offre de prêt. Le contrat d’assurance proposé par PRETTO est cohérent avec les besoins que vous avez exprimés et est formulée au moyen de fiches et de documents séparés. Note : cette recommandation personnalisée ne constitue pas un conseil au sens de l’article L. 519-1-1 du Code monétaire et financier, ou L. 313-13 du Code de la consommation.  Note : le prêteur peut conditionner l’octroi du crédit à la souscription de produits complémentaires par l’emprunteur. Les éventuels services accessoires au contrat de crédit seront présentés au moyen de documents spécifiques prévus par la Réglementation dont ceux-ci relèvent. Vous serez informé de la possibilité ou non de résilier chaque composante séparément et des implications d’une telle procédure.',
}
