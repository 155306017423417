import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferAppointmentCard = ({ advisor = '', onMakeAppointment }) => (
  <S.Card>
    <Card variant="white" format="full-height">
      <Content>
        <S.Content>
          <S.Title>
            <SubHeading>Vous avez des questions ?</SubHeading>
          </S.Title>
          <S.Description>
            Si vous avez des questions sur cette offre votre expert {`${advisor} `}est là pour y répondre.
          </S.Description>
          <S.Button>
            <ButtonSquared onClick={onMakeAppointment} variant="accent-1-l" textVariant="accent-1">
              Prendre Rendez-vous
            </ButtonSquared>
          </S.Button>
        </S.Content>
      </Content>
    </Card>
  </S.Card>
)

OfferAppointmentCard.propTypes = {
  /** Name of advisor. */
  advisor: PropTypes.string,
  /** Function to make appointment */
  onMakeAppointment: PropTypes.func.isRequired,
}

export default memo(OfferAppointmentCard)
