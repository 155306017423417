import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Stages from '@pretto/bricks/components/steps/Stages'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import { memo } from 'react'

import * as S from './styles'

const STEPS = ['Accord de principe', 'Rendez-vous à la banque', 'Signature de l’offre de prêt']

const OfferNextStepsCard = () => (
  <Card variant="white">
    <Content>
      <SubHeading>Quelles sont les prochaines étapes ?</SubHeading>
      <S.Description>
        La banque va vous appeler pour convenir d’un rendez-vous. Vous allez rencontrer votre nouveau conseiller et
        ouvrir vos comptes si vous n’êtes pas déjà client.
      </S.Description>
      <Stages items={STEPS} active={0} />
    </Content>
  </Card>
)

export default memo(OfferNextStepsCard)
