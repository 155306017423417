import Button from '@pretto/bricks/components/buttons/Button'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Sequence from '@pretto/bricks/components/utility/Sequence'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const InformNotaryPage = ({ Message, onInform, onCancel, BubbleChat }) => {
  return (
    <S.Page>
      <Wrapper>
        <S.Title>
          <Heading>Informons votre notaire</Heading>
        </S.Title>
        <Sequence>
          <S.BubbleChat>
            <BubbleChat>{Message}</BubbleChat>
          </S.BubbleChat>
          <S.Buttons>
            <S.Button>
              <Button onClick={onInform}>Prévenez le notaire</Button>
            </S.Button>
            <Button onClick={onCancel} isSecondary size="small">
              Non merci
            </Button>
          </S.Buttons>
        </Sequence>
      </Wrapper>
    </S.Page>
  )
}

InformNotaryPage.propTypes = {
  BubbleChat: PropTypes.func.isRequired,
  Message: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onInform: PropTypes.func.isRequired,
}
export default memo(InformNotaryPage)
