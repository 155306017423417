import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const AccountPage = ({ mayInviteComortgagor, sections }) => (
  <Fragment>
    <S.Header>
      <Wrapper>
        <Heading>Mon compte</Heading>
      </Wrapper>
    </S.Header>
    <Wrapper>
      <S.Page>
        {sections.map(({ fieldsComponent, title }, index) => (
          <S.Section key={index}>
            <S.SectionTitle>
              <SubHeading size="large">{title}</SubHeading>
            </S.SectionTitle>

            {fieldsComponent.map((fieldComponent, index) => (
              <S.Field key={index}>{fieldComponent}</S.Field>
            ))}
          </S.Section>
        ))}

        {mayInviteComortgagor && (
          <S.Invite>
            <Card format="line" variant="neutral-1-20">
              <Content>
                <S.InviteContent>
                  <SubHeading>Invitez votre co-emprunteur</SubHeading>
                  <ButtonSquared href="/invite" variant="accent-1">
                    Inviter
                  </ButtonSquared>
                </S.InviteContent>
              </Content>
            </Card>
          </S.Invite>
        )}

        <S.Delete>
          <S.DeleteButton href="https://faq.pretto.fr/fr/articles/837122">
            Demander la suppression de mon compte
          </S.DeleteButton>

          <S.DeleteLegals>
            Pour plus d’informations au sujet de la suppression de vos données personnelles chez Pretto, nous vous
            invitons à consulter directement notre{' '}
            <Link href="https://www.pretto.fr/privacy/">politique de confidentialité</Link>.
          </S.DeleteLegals>
        </S.Delete>
      </S.Page>
    </Wrapper>
  </Fragment>
)

AccountPage.defaultProps = {
  mayInviteComortgagor: false,
}

AccountPage.propTypes = {
  /** Whether or not the invite banner should be displayed. */
  mayInviteComortgagor: PropTypes.bool,
  /** Sections of page. */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      fieldsComponent: PropTypes.arrayOf(PropTypes.node).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default memo(AccountPage)
