import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Cards = styled.div`
  display: flex;
`
export const Card = styled.div`
  margin-right: ${g(1)};
  cursor: pointer;

  &:last-child {
    margin-right: 0;
    padding-right: ${g(2)};
    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-right: 0;
    }
  }
`
