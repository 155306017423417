import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const OfferRecommendedCard = ({ advisorAvatar, benefits }) => (
  <Card variant="neutral-1-20" format="line">
    <Content>
      <SubHeading>Pourquoi nous vous recommandons cette offre</SubHeading>
      <S.Content>
        <S.Avatar>{advisorAvatar}</S.Avatar>
        <S.Benefits>{benefits}</S.Benefits>
      </S.Content>
    </Content>
  </Card>
)

OfferRecommendedCard.propTypes = {
  /** Avatar shown next to text. */
  advisorAvatar: PropTypes.element.isRequired,
  /** Text of card. */
  benefits: PropTypes.string.isRequired,
}

export default memo(OfferRecommendedCard)
