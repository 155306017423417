import castArray from 'lodash/castArray'
import PropTypes from 'prop-types'
import { Children, Component } from 'react'

import * as C from './Sequence.module.css'

export default class Sequence extends Component {
  static propTypes = {
    children: PropTypes.node,
    delay: PropTypes.number,
    ctaDelay: PropTypes.number,
  }

  static defaultProps = {
    delay: 2000,
    ctaDelay: 1000,
  }

  state = {
    renderedIndex: 0,
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  componentDidMount() {
    this.renderNextChild()
  }

  renderNextChild = () => {
    const { delay, ctaDelay, children } = this.props
    const { renderedIndex } = this.state

    this.setState(prevState => ({ renderedIndex: prevState.renderedIndex + 1 }))

    const d = renderedIndex < Children.count(children) - 2 ? delay : ctaDelay

    this.timeout = setTimeout(() => {
      this.renderNextChild()
    }, d)

    if (renderedIndex === Children.count(children)) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    const items = castArray(
      Children.map(this.props.children, (child, i) => (
        <div key={i} className={C.sequenceItem}>
          {child}
        </div>
      ))
    )

    return <div>{items.slice(0, this.state.renderedIndex)}</div>
  }
}
