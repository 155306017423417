import InformNotaryPageComponent from '@pretto/bricks/app/offer/pages/InformNotaryPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { Mutation } from '@apollo/client/react/components'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import BubbleChat from '../../../SharedComponents/BubbleChat'
import Header from '../../../SharedContainers/Header'
import { CHOOSE_OFFER } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'

const PROPS = data => {
  const { refetch } = useAuth()
  const { notifyLegacy } = useNotifications()
  const trackAction = useTracking()

  const onAfterMutate = notary_notif => async (cache, response) => {
    trackAction('OFFER_VALIDATE_CLICKED', { offer_validate_clicked_inform_notary: notary_notif })
    if (response.data.choose_offer.success) {
      await refetch()
    }

    notifyLegacy('C’est noté !', notary_notif ? 'Nous allons contacter votre notaire.' : 'Votre expert a été informé.')

    data.history.replace('/')
  }

  const onChooseOffer = notary_notif => async () => {
    data.setLoading(true)
    await data.chooseOffer({
      update: onAfterMutate(notary_notif),
      variables: { notary_notif, project_kind: 'purchase' },
    })
  }

  const Message = (
    <p>
      Bravo ! Nous allons maintenant organiser un rendez-vous entre vous et votre nouvelle banque.
      <br />
      Pretto peut maintenant prévenir votre notaire que vous avez choisi la proposition d’une banque.
      <br />
      Nous vous le recommandons : cela permet de rassurer le vendeur sur la bonne avancée de votre recherche de prêt. De
      plus, le notaire pourra anticiper les prochaines étapes et vous pourrez signer plus rapidement.
    </p>
  )

  return {
    BubbleChat,
    Message,
    onCancel: onChooseOffer(false),
    onInform: onChooseOffer(true),
  }
}

const InformNotaryPage = props => {
  const { loading, history, chooseOffer } = props

  const [mutating, setMutating] = useState(false)

  if (loading) return <SpinnerLegacy overlay />
  return (
    <Fragment>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      {mutating && <SpinnerLegacy overlay />}
      <InformNotaryPageComponent {...PROPS({ chooseOffer, history, setLoading: setMutating })} />
    </Fragment>
  )
}

InformNotaryPage.propTypes = {
  advisor: PropTypes.object,
  chooseOffer: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.any,
  history: PropTypes.object,
  loading: PropTypes.bool,
}

const InformNotary = props => (
  <Mutation mutation={CHOOSE_OFFER}>
    {chooseOffer => <InformNotaryPage {...props} chooseOffer={chooseOffer} />}
  </Mutation>
)

export default InformNotary
