import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MandateCardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MandateCardQuery = { __typename?: 'Query', brokerage_fees?: { __typename?: 'BrokerageFees', id?: string | null, amount?: number | null } | null, deal?: { __typename?: 'Deal', id?: string | null, mandateStatus?: { __typename?: 'DealMandateStatus', signed?: boolean | null, signatures?: Array<{ __typename?: 'MandateSignature', id?: string | null, signedAt?: string | null }> | null } | null, sepaStatus?: { __typename?: 'SepaStatus', signed?: boolean | null } | null } | null };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const MandateCardDocument = gql`
    query MandateCard {
  brokerage_fees {
    id
    amount
  }
  deal {
    id
    mandateStatus {
      signed
      signatures {
        id
        signedAt
      }
    }
    sepaStatus {
      signed
    }
  }
}
    `;

/**
 * __useMandateCardQuery__
 *
 * To run a query within a React component, call `useMandateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandateCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useMandateCardQuery(baseOptions?: Apollo.QueryHookOptions<MandateCardQuery, MandateCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandateCardQuery, MandateCardQueryVariables>(MandateCardDocument, options);
      }
export function useMandateCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandateCardQuery, MandateCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandateCardQuery, MandateCardQueryVariables>(MandateCardDocument, options);
        }
export type MandateCardQueryHookResult = ReturnType<typeof useMandateCardQuery>;
export type MandateCardLazyQueryHookResult = ReturnType<typeof useMandateCardLazyQuery>;
export type MandateCardQueryResult = Apollo.QueryResult<MandateCardQuery, MandateCardQueryVariables>;