import SponsorshipPageComponent from '@pretto/bricks/app/sponsorship/pages/SponsorshipPage'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import { funcToItem } from '@pretto/bricks/core/utility/formatters'
import { compileString } from '@pretto/bricks/core/utility/string/compileString'

import * as filters from '@pretto/app-core/lib/filters'
import { isFieldValid } from '@pretto/app-core/lib/isFieldValid'
import { renderFields } from '@pretto/app-core/lib/renderFields'

import Header from '@pretto/app/src/SharedContainers/Header'
import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import fieldsSchema from '@pretto/app/src/Sponsorship/config/form'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { REFER_A_FRIEND, SPONSORSHIP } from '@pretto/app/src/apollo'
import { copyToClipboard } from '@pretto/app/src/lib/helpers'
import { useTracking } from '@pretto/app/src/lib/tracking'
import sponsorshipParams from '@pretto/config/sponsorship_params.json'

import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'

const INVITATIONS_MAXIMUM = 5
const GAINS_EMOJI = {
  ':clap:': 0,
  ':muscle:': 300,
}
const GAINS_EMOJI_DEFAULT = ':money_with_wings:'

const encoder = filters.applyFilters([
  filters.applyFilter('email', filters.formatEncodeEmail),
  filters.applyFilter('firstname', filters.formatEncodeProprify),
  filters.applyFilter('lastname', filters.formatEncodeProprify),
  filters.applyFilter('phone', filters.formatEncodePhone),
])

const emojiForGain = gain =>
  Object.entries(GAINS_EMOJI).reduce((previous, [emoji, total]) => {
    if (gain > total) {
      return emoji
    }

    return previous
  }, GAINS_EMOJI_DEFAULT)

const ERROR_DURATION = 5000

const SponsorshipPage = () => {
  const [referAFriend] = useMutation(REFER_A_FRIEND, { errorPolicy: 'all' })
  const { data, loading } = useQuery(SPONSORSHIP)
  const timeout = useRef(null)

  const [errors, setErrors] = useState({})
  const [hasError, setHasError] = useState(false)
  const [isConditionsDialogOpen, setIsConditionsDialogOpen] = useState(false)
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false)
  const [isMutating, setIsMutating] = useState(false)
  const [maximum, setMaximum] = useState(INVITATIONS_MAXIMUM)
  const [values, setValues] = useState({})

  const trackAction = useTracking()
  const { isMobile } = useBreakpoint()

  const { advisor, isLowPotential } = useUser()

  useEffect(() => {
    const clear = () => {
      timeout.current = null

      if (!timeout.current) {
        clearTimeout(timeout.current)
      }
    }

    clear()

    if (hasError) {
      timeout.current = setTimeout(() => setHasError(false), ERROR_DURATION)
    }

    return clear
  }, [hasError])

  if (isLowPotential) {
    return <Redirect to="/" />
  }

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  const {
    new_sponsorship: { link, totalReferees, totalGains, referees },
  } = data

  const shareForType = (link, kind) => {
    const encodedLink = encodeURI(link)

    const quote = 'Je recommande Pretto pour votre crédit immobilier !'
    const text = encodeURI(`${quote} Pour que je vous parraine, cliquez là 👉 ${encodedLink}`)
    const whatsappText = `Je te recommande Pretto pour trouver ton crédit immobilier ! Pour que je te parraine, inscris-toi là 👉 ${encodedLink}`

    const urls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${quote}`,
      twitter: `https://twitter.com/intent/tweet/?url=${encodedLink}&text=${text}&via=hellopretto`,
      whatsapp: `https://api.whatsapp.com/send?text=${whatsappText}`,
    }

    const handleClick = () => {
      window.open(urls[kind], '_blank')
      trackAction('REFERRAL_SHARE_BUTTON_CLICKED', { referral_share_button_option: kind })
    }

    return {
      kind,
      onClick: handleClick,
    }
  }

  const handleChange = (name, value, error) => {
    setErrors({ ...errors, [name]: funcToItem(error) })
    setValues({ ...values, [name]: value })
  }

  const handleCopyLink = () => {
    copyToClipboard(`http://${link}`)
    trackAction('REFERRAL_LINK_CLICKED')
  }

  const handleConditionsDialogClose = () => setIsConditionsDialogOpen(false)
  const handleConditionsDialogOpen = () => setIsConditionsDialogOpen(true)

  const handleInviteDialogClose = () => {
    if (isMutating) {
      return
    }

    setIsInviteDialogOpen(false)
  }

  const handleInviteDialogOpen = () => setIsInviteDialogOpen(true)

  const handleMore = () => setMaximum(Infinity)

  const handleSubmit = async () => {
    setIsMutating(true)

    const encodedValues = await encoder(values)

    referAFriend({
      update: (cache, { data, errors }) => {
        setIsInviteDialogOpen(false)
        setIsMutating(false)

        if (errors) {
          setHasError(true)
          return
        }

        const {
          new_referFriend: { sponsorship: newSponsorship },
        } = data

        cache.modify({
          id: cache.identify(data.sponsorship),
          fields: {
            referees() {
              return newSponsorship.referees
            },
          },
        })

        setValues({})

        trackAction('REFERRAL_FORM_BUTTON_CLICKED')
      },
      variables: encodedValues,
    })
  }

  const fields = renderFields(fieldsSchema, handleChange, values)

  const invitations = referees.slice(0, maximum)

  const isDisabled =
    fields.reduce((previous, field) => previous || !isFieldValid(values[field.key]), false) ||
    Object.values(errors).some(error => error === true)

  const plural = totalReferees > 1 ? 's' : ''
  const highlights = [
    {
      label: `Invitation${plural} envoyée${plural}`,
      value: totalReferees.toString(),
    },
    {
      icon: emojiForGain(totalGains),
      label: 'Empoché',
      value: `${totalGains.toLocaleString('fr')} €`,
    },
  ]

  const headline = compileString(sponsorshipParams.headline, {
    ...sponsorshipParams,
    advisorFullname: advisor?.name ?? 'Votre expert Pretto',
  })

  const props = {
    fields,
    hasError,
    hasMore: invitations.length < totalReferees,
    headline,
    highlights,
    invitations,
    isConditionsDialogOpen,
    isDisabled,
    isInviteDialogOpen,
    isMutating,
    onConditionsDialogClose: handleConditionsDialogClose,
    onConditionsDialogOpen: handleConditionsDialogOpen,
    onInviteDialogClose: handleInviteDialogClose,
    onInviteDialogOpen: handleInviteDialogOpen,
    onMore: handleMore,
    onSubmit: handleSubmit,
  }

  const links = isMobile ? ['facebook', 'twitter', 'whatsapp'] : ['facebook', 'twitter']

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />
      <SponsorshipPageComponent
        {...props}
        sponsorshipShareProps={{
          link,
          links: links.map(shareForType.bind(null, link)),
          onClick: handleCopyLink,
        }}
      />
    </>
  )
}

export default SponsorshipPage
