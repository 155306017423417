import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CapacityCardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CapacityCardQuery = { __typename?: 'Query', capacity: Array<{ __typename?: 'Result', financing?: { __typename?: 'Financing', loanDuration?: number | null } | null, project?: { __typename?: 'Project', purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null }> };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const CapacityCardDocument = gql`
    query CapacityCard {
  capacity: new_capacity {
    financing {
      loanDuration
    }
    project {
      purchase {
        property_price
      }
    }
  }
}
    `;

/**
 * __useCapacityCardQuery__
 *
 * To run a query within a React component, call `useCapacityCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapacityCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacityCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useCapacityCardQuery(baseOptions?: Apollo.QueryHookOptions<CapacityCardQuery, CapacityCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapacityCardQuery, CapacityCardQueryVariables>(CapacityCardDocument, options);
      }
export function useCapacityCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapacityCardQuery, CapacityCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapacityCardQuery, CapacityCardQueryVariables>(CapacityCardDocument, options);
        }
export type CapacityCardQueryHookResult = ReturnType<typeof useCapacityCardQuery>;
export type CapacityCardLazyQueryHookResult = ReturnType<typeof useCapacityCardLazyQuery>;
export type CapacityCardQueryResult = Apollo.QueryResult<CapacityCardQuery, CapacityCardQueryVariables>;