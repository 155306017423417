import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Description = styled.div`
  margin: ${g(1)} 0;
`
export const Row = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`
