import * as Types from '@pretto/app/src/types/gateway/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', advisor?: { __typename?: 'Advisor', id?: string | null, managerEmail?: string | null, reviews?: Array<{ __typename?: 'Review', consumerName?: string | null, description?: string | null, kind?: Types.Kind | null, postedDate?: string | null, stars?: number | null, title?: string | null }> | null } | null, deal?: { __typename?: 'Deal', agreement?: Types.AgreementEnum | null, certificate?: string | null, hasBeenDelayed?: boolean | null, id?: string | null, isLowPotential?: boolean | null, estimatedResponseDate?: string | null, lateEstimatedResponseDate?: string | null, status?: Types.StatusEnum | null, waitingStep?: Types.Waiting_Step | null, mandateStatus?: { __typename?: 'DealMandateStatus', signed?: boolean | null, signatures?: Array<{ __typename?: 'MandateSignature', id?: string | null, signedAt?: string | null }> | null } | null, sepaStatus?: { __typename?: 'SepaStatus', signed?: boolean | null } | null } | null, project?: { __typename?: 'Project', id?: string | null, purchase?: { __typename?: 'Purchase', build_price?: number | null, land_price?: number | null, maturity?: Types.Maturity | null, property_price?: number | null } | null, renegotiation?: { __typename?: 'Renegotiation', estimated_value?: number | null } | null } | null };

export type CapacityResultFragment = { __typename?: 'Result', financing?: { __typename?: 'Financing', applicationFees?: number | null, brokerageFees?: number | null, guarantyFees?: number | null, interests?: number | null, loanAmount?: number | null, loanDuration?: number | null, mainLoansRate?: number | null, payment?: number | null, paymentAfterBridge?: number | null, bridge?: { __typename?: 'FinancingBridge', remaining?: number | null, value?: number | null } | null, ptz?: { __typename?: 'FinancingPTZ', amount?: number | null, duration?: number | null, franchise?: number | null } | null } | null, project?: { __typename?: 'Project', contribution?: number | null, facts?: { __typename?: 'Facts', notary_fees?: number | null } | null, purchase?: { __typename?: 'Purchase', property_price?: number | null } | null } | null };

export type LocationFragment = { __typename?: 'Localisation', city?: string | null, country?: string | null, street?: string | null, zipcode?: string | null };

export type IncomeDetailsFragment = { __typename?: 'Income', amount?: number | null, period?: Types.Recurrence | null, taxes?: Types.Taxes | null };

export const CapacityResultFragmentDoc = gql`
    fragment CapacityResult on Result {
  financing {
    applicationFees
    bridge {
      remaining
      value
    }
    brokerageFees
    guarantyFees
    interests
    loanAmount
    loanDuration
    mainLoansRate
    payment
    paymentAfterBridge
    ptz {
      amount
      duration
      franchise
    }
  }
  project {
    contribution
    facts {
      notary_fees
    }
    purchase {
      property_price
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Localisation {
  city
  country
  street
  zipcode
}
    `;
export const IncomeDetailsFragmentDoc = gql`
    fragment IncomeDetails on Income {
  amount
  period
  taxes
}
    `;
export const DashboardDocument = gql`
    query Dashboard {
  advisor {
    id
    managerEmail
    reviews {
      consumerName
      description
      kind
      postedDate
      stars
      title
    }
  }
  deal {
    agreement
    certificate
    hasBeenDelayed
    id
    isLowPotential
    estimatedResponseDate
    lateEstimatedResponseDate
    mandateStatus {
      signatures {
        id
        signedAt
      }
      signed
    }
    sepaStatus {
      signed
    }
    status
    waitingStep
  }
  project {
    id
    purchase {
      build_price
      land_price
      maturity
      property_price
    }
    renegotiation {
      estimated_value
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;