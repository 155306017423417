import { g } from '@pretto/bricks/components/layout'

import {
  DefaultArrow as DefaultArrowComponent,
  DefaultMessage as DefaultMessageComponent,
  FollowTooltip as FollowTooltipComponent,
} from '@pretto/zen/reveal/atoms/infos/FollowTooltip/FollowTooltip'
import { defaultDuration, transition } from '@pretto/zen/reveal/lib/transitionCss'
import type { FieldState } from '@pretto/zen/reveal/types/Field'

import styled, { css } from 'styled-components'

interface StateProps {
  $state: FieldState
}

export const ClearButton = styled.button`
  color: ${({ theme }) => theme.colors.neutral1};
  cursor: pointer;

  svg {
    display: block;
  }
`

interface FieldProps extends StateProps {
  $isSubmittable: boolean
}

export const Field = styled.span<FieldProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-radius: ${g(1 / 2)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: inline-flex;
  gap: ${g(1)};
  padding: ${g(1, 4)} ${g(2)};

  ${({ $isSubmittable }) =>
    $isSubmittable &&
    css`
      ${transition(
        { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'padding-left' },
        { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'padding-right' },
        { propertyName: 'padding-bottom' },
        { propertyName: 'padding-top' }
      )};
      padding: ${g(1)} ${g(2)};

      &&:focus-within {
        padding: ${g(1, 4)} ${g(6)} ${g(1, 4)} ${g(2)};
      }
    `}

  ${({ $state }) => {
    switch ($state) {
      case 'error':
        return css`
          border-color: ${({ theme }) => theme.colors.error1};
        `

      case 'warning':
        return css`
          border-color: ${({ theme }) => theme.colors.warning1};
        `

      case 'default':
      default:
        return css`
          border-color: ${({ theme }) => theme.colors.primary1};
          color: ${({ theme }) => theme.colors.primary1};
        `
    }
  }}
`

export const FollowTooltip = styled(FollowTooltipComponent).attrs<StateProps>(({ $state }) => ({
  arrowComponent: FollowTooltipArrow,
  arrowComponentProps: { $state },
  messageComponent: FollowTooltipMessage,
  messageComponentProps: { $state },
  offset: g(2, 4),
}))<StateProps>`
  outline: 0px transparent;
`

const FollowTooltipMessage = styled(DefaultMessageComponent)<StateProps>`
  ${({ $state }) => {
    switch ($state) {
      case 'error':
        return css`
          background-color: ${({ theme }) => theme.colors.error2};
          color: ${({ theme }) => theme.colors.error1};
        `

      case 'warning':
        return css`
          background-color: ${({ theme }) => theme.colors.warning2};
          color: ${({ theme }) => theme.colors.warning1};
        `

      case 'default':
      default:
        return null
    }
  }}
`

const FollowTooltipArrow = styled(DefaultArrowComponent)<StateProps>`
  ${({ $state }) => {
    switch ($state) {
      case 'error':
        return css`
          border-bottom-color: ${({ theme }) => theme.colors.error2};
        `

      case 'warning':
        return css`
          border-bottom-color: ${({ theme }) => theme.colors.warning2};
        `

      case 'default':
      default:
        return null
    }
  }}
`

export const Input = styled.input`
  color: inherit;
  font: inherit;
  padding: 0;
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% + 2px);
  z-index: 1;

  &:focus {
    outline: transparent;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral3};
  }
`

interface InteractiveProps {
  $isEmpty: boolean
}

export const Interactive = styled.span<InteractiveProps>`
  ${({ $isEmpty, theme }) => ($isEmpty ? theme.typos.body4 : theme.typos.body4Bold)};
  position: relative;
`

export const Picto = styled.span`
  display: block;
  flex-shrink: 0;
  height: ${g(2)};
  position: relative;
  width: ${g(2)};
`

const PictoComponent = styled.svg<StateProps>`
  ${transition({ propertyName: 'opacity' })};
  height: inherit;
  position: absolute;
  width: inherit;

  ${({ $state }) => {
    switch ($state) {
      case 'error':
        return css`
          color: ${({ theme }) => theme.colors.error1};
        `

      case 'warning':
        return css`
          color: ${({ theme }) => theme.colors.warning1};
        `

      case 'default':
      default:
        return css`
          color: ${({ theme }) => theme.colors.primary1};
        `
    }
  }};
`

interface PictoProps {
  $isIn: boolean
}

export const PictoBlur = styled(PictoComponent)<PictoProps>`
  opacity: ${({ $isIn }) => ($isIn ? 1 : 0)};

  ${FollowTooltip}:focus && {
    opacity: 0;
  }
`

export const PictoFocus = styled(PictoComponent)<PictoProps>`
  opacity: 0;

  ${FollowTooltip}:focus && {
    opacity: ${({ $isIn }) => ($isIn ? 1 : 0)};
  }
`

export const Placeholder = styled.pre`
  display: inline;
  font: inherit;
  visibility: hidden;
`

export const SubmitButton = styled.button`
  ${transition(
    { propertyName: 'opacity' },
    { delay: defaultDuration, duration: '0s', propertyName: 'visibility' },
    { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'transform' },
    { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'width' }
  )};
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  border: 0;
  border-radius: ${g(1 / 2)};
  bottom: 0;
  cursor: pointer;
  display: inline-flex;
  height: ${g(4)};
  justify-content: center;
  left: ${g(1)};
  margin: auto;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translateX(${g(-6)});
  visibility: hidden;
  width: ${g(4)};
  z-index: 0;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary1Disabled};
    cursor: not-allowed;
  }

  ${Field}:focus-within && {
    ${transition(
      { propertyName: 'opacity' },
      { delay: '0s', duration: '0s', propertyName: 'visibility' },
      { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'transform' },
      { easing: 'cubic-bezier(0.34, 1.56, 0.64, 1)', propertyName: 'width' }
    )};
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
`

export const SubmitButtonContainer = styled.span`
  position: relative;
  margin-left: ${g(-1)};
`
