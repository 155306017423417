import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'

const Signature: React.FC<CardProps> = ({ defaultComponent: Component }) => (
  <Component
    description="Vous recevez l'offre de prêt et attendez 11 jours avant de la renvoyer signée."
    title="Signez l'offre de prêt"
  />
)

export const config: Config = {
  component: Signature,
  name: 'signature',
}
