import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import { MONTHS_IN_YEAR } from '../../lib/constants'

import { BANK_CRITERIAS, DETAILS_LABELS, LABELS, TITLES } from './config'

const DETAILS = [
  ['amount', 'bridgeValue', 'duration', 'franchise', 'rate'],
  ['payment', 'insurancePayment'],
  [
    'interests',
    'guaranty',
    'insuranceCost',
    'applicationFees',
    'brokerageFees',
    'renegotiationSavings',
    'totalCost',
    'taeg',
  ],
]
export const encodeDetails = (offer, data) => {
  const elements = {
    ...offer,
    contribution: data.contribution,
    duration: offer.duration / MONTHS_IN_YEAR,
    totalCost: offer.insuranceCost + offer.interests + offer.guaranty + offer.applicationFees,
  }

  const details = DETAILS.map(sections =>
    sections
      .map(key => {
        const value = elements[key]
        if (value) {
          const [label, suffix, func, variant] = DETAILS_LABELS[key]
          const options = {
            title: label,
          }
          if (variant) Object.assign(options, { variant })
          if (func)
            return {
              ...options,
              value: funcToItem(func, value),
            }
          return {
            ...options,
            value: `${value.toLocaleString('fr')} ${suffix}`,
          }
        }
        return null
      })
      .filter(Boolean)
  )
  return details
}

export const encodeBankCriterias = offer =>
  BANK_CRITERIAS.reduce((previous, { descriptionKey, label, valueKey }) => {
    const value = offer.bankCriterias[valueKey]

    if (!value) {
      return previous
    }

    return [
      ...previous,
      {
        description: offer.bankCriterias[descriptionKey] ?? null,
        label,
        value,
      },
    ]
  }, [])

export const encodeList = list =>
  list.map(item => ({
    checked: true,
    label: LABELS[item],
  }))

export const encodeOffers = data =>
  data.offers.map(offer => ({
    bank: {
      description: offer.bankDescription,
      name: offer.bankName,
      slug: offer.bankLogo,
    },
    concessions: {
      items: encodeList(offer.concessions),
      title: TITLES.concessions,
    },
    bankCriterias: {
      items: encodeBankCriterias(offer),
      title: TITLES.bankCriterias,
    },
    details: encodeDetails(offer, data),
    duration: offer.duration,
    guarantees: {
      items: encodeList(offer.insurancePolicies),
      title: TITLES.guarantees,
    },
    payment: offer.payment,
    rate: offer.rate,
  }))
