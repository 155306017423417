import OfferPageComponent from '@pretto/bricks/app/offer/pages/OfferPage'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import { Desktop, Mobile } from '@pretto/bricks/components/utility/Responsive'

import { useNotifications } from '@pretto/app-core/notifications/notifications'

import { GO_BACK, NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from '../../../Auth/Containers/AuthProvider'
import AdvisorAvatar from '../../../SharedContainers/AdvisorAvatar'
import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { AGREEMENT, CHOOSE_OFFER } from '../../../apollo'
import { useTracking } from '../../../lib/tracking'
import { DESCRIPTIONS, TITLES } from '../../lib/config'
import { encodeOffers } from '../../lib/encoders'

const useProps = data => {
  const { notifyLegacy } = useNotifications()
  const [currentOffer, onChangeOffer] = useState(0)
  const trackAction = useTracking()
  const offers = encodeOffers(data)
  const offer = offers[currentOffer]
  const isRecommended = currentOffer === 0
  const handleChangeOffer = index => () => {
    onChangeOffer(index)
    trackAction('OFFER_BANK_CLICKED', {
      offer_bank_clicked_index: index,
      offer_bank_clicked_name: offer.bank.name,
      offer_bank_clicked_rate: offer.rate,
    })
  }

  const cards = offers.map((offer, index) => ({
    bank: offer.bank.slug,
    isSelected: currentOffer === index,
    onClick: handleChangeOffer(index),
    rate: offer.rate,
  }))

  const onAfterMutate = async (cache, response) => {
    if (response.data.choose_offer.success) {
      if (response.data.choose_offer.success) {
        await data.refetch()
      }
    }

    notifyLegacy('C’est noté !', 'Votre expert a été informé.')
  }

  const handleChooseOffer = clickLocation => async () => {
    const isRenegotiation = data.projectKind === 'renegotiation' && isRecommended
    const path = isRenegotiation ? '/dashboard' : isRecommended ? '/inform-notary' : '/booking'
    trackAction('OFFER_CHOOSE_CLICKED', {
      offer_choose_clicked_destination: path,
      offer_choose_clicked_source: clickLocation,
    })
    if (isRenegotiation) {
      await data.chooseOffer({
        update: onAfterMutate,
        variables: { notary_notif: false, project_kind: data.projectKind },
      })
    }
    data.history.push(path)
  }

  const handleMakeAppointment = () => {
    trackAction('OFFER_APPOINTMENT_CLICKED')
    data.history.push('/booking')
  }

  const props = {
    advisorAvatar: <AdvisorAvatar />,
    advisorName: data.advisor.name,
    agreement: data.agreementStage,
    benefits: data.comment,
    cards,
    description: DESCRIPTIONS.main,
    detailsDescription: DESCRIPTIONS.details,
    emoji: ':tada:',
    isMobile: data.isMobile,
    isRecommended,
    legal: DESCRIPTIONS.legal,
    offer,
    onMakeAppointment: handleMakeAppointment,
    title: `${TITLES.main} !`,
  }

  if (data.agreementStage === 'promoted') {
    Object.assign(props, {
      onChooseOfferButton: handleChooseOffer('button'),
      onChooseOfferCard: handleChooseOffer('card'),
    })
  }

  return props
}

const OfferPage = props => {
  const formattedProp = useProps(props)

  return <OfferPageComponent {...formattedProp} />
}

OfferPage.defaultProps = {
  isMobile: false,
}

OfferPage.propTypes = {
  isMobile: PropTypes.bool,
}

const OfferPageContainer = ({ history }) => {
  const { refetch } = useAuth()
  const { advisor, agreement: agreementStage } = useUser()

  const [chooseOffer] = useMutation(CHOOSE_OFFER)
  const { data, loading } = useQuery(AGREEMENT)

  if (loading) {
    return <SpinnerLegacy overlay />
  }

  const { agreement, project } = data

  if (!agreement || agreementStage === 'pending') {
    return <Redirect to="/" />
  }

  const offerProps = {
    ...agreement,
    ...project,
    advisor,
    agreementStage,
    chooseOffer,
    history,
    refetch,
  }

  return (
    <>
      <Header goBackProps={GO_BACK.dashboard} navigationItemsList={[NAV_ITEMS.faq]} />

      <Mobile>
        <OfferPage {...offerProps} isMobile />
      </Mobile>

      <Desktop>
        <OfferPage {...offerProps} />
      </Desktop>
    </>
  )
}

OfferPageContainer.propTypes = {
  history: PropTypes.object,
  isMobile: PropTypes.bool,
}

export default OfferPageContainer
