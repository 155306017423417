import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Content = styled.div`
  display: flex;
  margin-top: ${g(3)};
`

export const Avatar = styled.div`
  margin-right: ${g(2)};
`

export const Benefits = styled.div`
  white-space: pre-line;
`
export const Benefit = styled.p`
  margin-bottom: ${g(2)};
`
