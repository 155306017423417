import Responsive from '@pretto/bricks/components/utility/Responsive'

import { DividerVertical } from '@pretto/zen/atoms/dividers/DividerVertical/DividerVertical'

import * as S from './PartnerBlock.styles'

export interface PartnerBlockProps {
  logo: string
  description: React.ReactElement
  onCTAClick: () => void
  rtb: string[]
  cta: {
    label: string
    labelMobile: string
    href: string
  }
}

export const PartnerBlock = ({ logo, description, onCTAClick, rtb, cta, ...props }: PartnerBlockProps) => {
  return (
    <S.PartnerContainer {...props}>
      <S.Logo>
        <S.PartnerLogo path={logo} />
        <DividerVertical />
        <S.PrettoLogotype />
      </S.Logo>
      <S.PartnerDescription>{description}</S.PartnerDescription>
      <Responsive max="mobileL">
        <S.Cta onClick={onCTAClick} href={cta.href}>
          {cta.labelMobile}
        </S.Cta>
      </Responsive>
      <Responsive min="mobileL">
        <S.Cta onClick={onCTAClick} href={cta.href}>
          {cta.label}
        </S.Cta>
      </Responsive>
      <S.PartnerRTBContainer>
        {rtb.map(reason => {
          return (
            <S.PartnerRTB key={reason}>
              <S.Picto />
              {reason}
            </S.PartnerRTB>
          )
        })}
      </S.PartnerRTBContainer>
    </S.PartnerContainer>
  )
}
