import { breakpoints, g } from '@pretto/bricks/components/layout'
import { random } from '@pretto/bricks/core/utility/hash'

import { defaultDuration, transition } from '@pretto/zen/reveal/lib/transitionCss'

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

type HPosition = 'left' | 'right'
type VPosition = 'above' | 'below'

interface AlignmentProps {
  $offset: string
  $hPosition: HPosition
  $vPosition: VPosition
}

export interface FollowTooltipProps {
  arrowComponent?: React.ComponentType
  arrowComponentProps?: object
  children: React.ReactNode
  message: React.ReactNode
  messageComponent?: React.ComponentType
  messageComponentProps?: object
  offset?: string
}

export interface FollowTooltipRef {
  focus: () => void
}

const Container = styled.button<AlignmentProps>`
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: help;
  display: inline-flex;
  flex-direction: ${({ $vPosition }) => ($vPosition === 'below' ? 'column-reverse' : 'column')};
  font: inherit;
  padding: 0;
  text-align: inherit;
`

const toggle = css<AlignmentProps>`
  opacity: 0;
  transform: translateY(calc(${({ $vPosition }) => ($vPosition === 'below' ? `100% - ${g(1)}` : `-100% + ${g(1)}`)}));
  ${transition(
    { propertyName: 'opacity' },
    { propertyName: 'transform' },
    { delay: defaultDuration, duration: '0s', propertyName: 'visibility' }
  )};
  visibility: hidden;

  ${Container}:focus &&,
  ${Container}:focus-within &&,
  ${Container}:hover && {
    opacity: 1;
    transform: translateY(${({ $vPosition }) => ($vPosition === 'below' ? '100%' : '-100%')});
    ${transition(
      { propertyName: 'opacity' },
      { propertyName: 'transform' },
      { delay: '0s', duration: '0s', propertyName: 'visibility' }
    )};
    visibility: visible;
  }
`

export const DefaultArrow = styled.span<AlignmentProps>`
  ${toggle};
  align-self: center;
  border-${({ $vPosition }) => ($vPosition === 'below' ? 'bottom' : 'top')}: ${g(1)} solid ${({ theme }) =>
    theme.colors.neutral1};
  border-left: ${g(1)} solid transparent;
  border-right: ${g(1)} solid transparent;
  cursor: default;
  height: 0;
  margin-${({ $vPosition }) => ($vPosition === 'below' ? 'bottom' : 'top')}: calc(-${({ $offset }) => $offset});
  position: absolute;
  width: 0;
`

export const DefaultMessage = styled.span<AlignmentProps>`
  ${toggle};
  background-color: ${({ theme }) => theme.colors.neutral1};
  border-radius: ${g(1)};
  color: ${({ theme }) => theme.colors.white};
  cursor: default;
  left: ${g(2, 4)};
  margin-${({ $vPosition }) => ($vPosition === 'below' ? 'bottom' : 'top')}: calc(${({ $offset }) =>
    `${g(-1)} - ${$offset}`});
  padding: ${g(2)};
  position: absolute;
  right: ${g(2, 4)};

  ${({ $hPosition }) =>
    $hPosition === 'right' &&
    css`
      align-self: flex-end;
    `}

  @media screen and (min-width: ${breakpoints.laptop}) {
    left: auto;
    margin-${({ $hPosition }) => $hPosition}: ${g(-2)};
    right: auto;
  }
`

export const FollowTooltip = forwardRef<FollowTooltipRef, FollowTooltipProps>(
  (
    {
      arrowComponent: Arrow = DefaultArrow,
      arrowComponentProps = {},
      children,
      message,
      messageComponent: Message = DefaultMessage,
      messageComponentProps = {},
      offset = g(1),
      ...props
    },
    ref
  ) => {
    useImperativeHandle(
      ref,
      () => ({
        focus() {
          innerRef.current?.focus()
        },
      }),
      []
    )

    const innerRef = useRef<HTMLButtonElement>(null)
    const { current: id } = useRef(random())

    const [hPosition, setHPosition] = useState<HPosition>('left')
    const [vPosition, setVPosition] = useState<VPosition>('below')

    useEffect(() => {
      const intersectHandler =
        (callback: (isIntersecting: boolean) => void) => (entries: IntersectionObserverEntry[]) => {
          entries.forEach(entry => {
            callback(entry.isIntersecting)
          })
        }

      const hObserver = new IntersectionObserver(
        intersectHandler(isIn => {
          setHPosition(isIn ? 'left' : 'right')
        }),
        { rootMargin: '0px -50% 0px 0px' }
      )

      const vObserver = new IntersectionObserver(
        intersectHandler(isIn => {
          setVPosition(isIn ? 'below' : 'above')
        }),
        { rootMargin: '0px 0px -50% 0px' }
      )

      if (innerRef.current) {
        hObserver.observe(innerRef.current)
        vObserver.observe(innerRef.current)
      }

      return () => {
        hObserver.disconnect()
        vObserver.disconnect()
      }
    }, [])

    if (!message) {
      return <>{children}</>
    }

    const alignmentProps = {
      $offset: offset,
      $hPosition: hPosition,
      $vPosition: vPosition,
    }

    return (
      <Container
        {...alignmentProps}
        {...props}
        aria-describedby={id}
        aria-label="Plus d'informations"
        ref={innerRef}
        type="button"
      >
        <span>{children}</span>

        <Arrow {...alignmentProps} {...arrowComponentProps} />

        <Message {...alignmentProps} {...messageComponentProps} $offset={offset} id={id} key={hPosition + vPosition}>
          {message}
        </Message>
      </Container>
    )
  }
)

FollowTooltip.displayName = 'FollowTooltip'
