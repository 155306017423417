import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Details = styled.div`
  margin-bottom: ${g(2)};
  display: flex;
  align-items: flex-start;
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }
`

export const Image = styled.div`
  flex: 0 0 ${g(9)};
  width: ${g(9)};
  height: ${g(9)};
  margin-right: ${g(2)};
  border-radius: 2px;
  overflow: hidden;

  img {
    width: 100%;
  }
`

export const Bank = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${g(3)};
  }
`

export const Infos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: baseline;
  }
`

export const Rate = styled.div`
  flex: 1 0 100%;
  margin: ${g(2)} 0 ${g(1)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: initial;
    margin: 0 ${g(4)} 0 0;
  }
`

export const Duration = styled.div`
  margin-right: ${g(4)};
`

export const About = styled.div`
  margin-bottom: ${g(2)};
`
