import styled, { css } from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Component = styled.div`
  display: grid;
  grid-auto-columns: ${g(2)} 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${g(2)};
  grid-template-rows: 4px repeat(${({ length }) => length}, auto) 4px;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-auto-flow: row;
    grid-gap: ${g(3)} 0;
    grid-template-columns: ${g(3)} repeat(${({ length }) => length}, auto) ${g(3)};
    grid-template-rows: initial;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: ${g(8)} repeat(${({ length }) => length}, auto) ${g(8)};
  }
`
export const ExtraDecoration = styled.div`
  margin-left: calc(50% - 2px);
  width: 4px;
  height: 100%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.legacy.colors.accent1.default : theme.legacy.colors.neutral1.fade(20)};

  ${({ isFirst }) =>
    isFirst
      ? css`
          border-radius: 4px 4px 0 0;
        `
      : css`
          border-radius: 0 0 4px 4px;
        `};

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 4px;
    width: 100%;
    margin-left: 0;
    margin-top: ${g(0.75)};

    ${({ isFirst }) =>
      isFirst
        ? css`
            border-radius: 4px 0 0 4px;
          `
        : css`
            border-radius: 0 4px 4px 0;
          `};
  }
`
export const Bullet = styled.div`
  border-radius: ${g(2)};
  height: ${g(2)};
  width: ${g(2)};
`

export const Decoration = styled.div`
  grid-row-start: ${({ position }) => position};
  position: relative;
  display: flex;
  align-items: center;
  height: ${g(6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: initial;
    grid-row-start: initial;
    margin-left: 4px;
    height: initial;
  }

  ${Bullet} {
    background: ${({ isActive, theme }) =>
      isActive ? theme.legacy.colors.accent1.default : theme.legacy.colors.neutral1.fade(20)};
  }

  &:before,
  &:after {
    background: ${({ isActive, theme }) =>
      isActive ? theme.legacy.colors.accent1.default : theme.legacy.colors.neutral1.fade(20)};
    position: absolute;
    content: '';
    left: calc(50% - 2px);
    right: calc(50% - 2px);
    z-index: ${({ position, length }) => length + 1 - position};

    @media screen and (min-width: ${breakpoints.tablet}) {
      left: ${g(2.5)};
      right: 0;
      top: ${g(0.75)};
      height: 4px;
      border-radius: 0;
      background: ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
    }
  }

  &:before {
    top: 0;
    bottom: calc(50% + ${g(1.5)});
    @media screen and (min-width: ${breakpoints.tablet}) {
      bottom: initial;
      top: ${g(0.75)};
      z-index: 1;

      ${({ isActive, theme }) =>
        isActive
          ? css`
              background: ${theme.legacy.colors.accent1.default};
              width: 25%;
              border-radius: 0 4px 4px 0;
            `
          : css`
              display: none;
            `};
    }
  }

  &:after {
    bottom: 0;
    top: calc(50% + ${g(1.5)});
    ${({ isActive }) =>
      isActive &&
      css`
        border-radius: 0 0 4px 4px;
        bottom: -2px;
      `};

    @media screen and (min-width: ${breakpoints.tablet}) {
      background: ${({ nextIsActive, theme }) =>
        nextIsActive ? theme.legacy.colors.accent1.default : theme.legacy.colors.neutral1.fade(20)};
      bottom: initial;
      top: ${g(0.75)};
      border-radius: 0;
      z-index: 0;
    }
  }
`
export const Stage = styled.div`
  align-self: center;
  grid-row-start: ${({ position }) => position};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-start;
    grid-column-start: ${({ position }) => position};
    grid-row-start: 2;
    margin-left: ${g(1.5)};
  }
`
